import { getTicketInfo } from 'api/ticketgeneratorpage';
import { useQuery } from 'react-query';

export const useTicketQuery = (id?: string) => {
  if (!id) {
    throw Error('No ticket id found!');
  }

  const { data, isFetching } = useQuery(`ticket-${id}`, async () => await getTicketInfo(id));

  return { data, isFetching };
};
