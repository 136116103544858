import { ChakraProvider } from '@chakra-ui/react';
import { App } from 'App';
import { AxiosInterceptor } from 'api/AxiosInterceptor';
import { refreshApi } from 'api/auth/refreshToken';
import { CartContextProvider } from 'context/CartContext/CartContext';
import React from 'react';
import { AuthProvider } from 'react-auth-kit';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'utils/toast';
import { LanguageProvider } from './context/LanguageContext/LanguageContext';
import { theme } from './styles/theme';
import { Fonts } from 'components/Fonts/Fonts';
import { HoldTokenSessionTimerProvider } from 'context/HoldTokenSessionTimerContext/HoldTokenSessionTimerContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      staleTime: 60 * 1000,
    },
  },
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <AuthProvider
      authType='localstorage'
      authName='_auth'
      cookieDomain={window.location.hostname}
      cookieSecure={false}
      refresh={refreshApi}
    >
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <LanguageProvider>
            <CartContextProvider>
              <HoldTokenSessionTimerProvider>
                <AxiosInterceptor>
                  {/* cssVarsRoot is required for use with Firefox */}
                  <ChakraProvider cssVarsRoot=':root' theme={theme}>
                    <Fonts />
                    <App />
                    <ToastContainer />
                  </ChakraProvider>
                </AxiosInterceptor>
              </HoldTokenSessionTimerProvider>
            </CartContextProvider>
          </LanguageProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>,
);
