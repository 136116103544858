import {
  Alert,
  AlertDescription,
  AlertTitle,
  Image,
  Text,
  ListItem,
  List,
  Box,
} from '@chakra-ui/react';
import microphone from '../../assets/EventAnnouncementsMicImg.png';
import { PulsatingCircle } from 'components/PulsatingCircle/PulsatingCircle';

interface EventAnnouncementsAlertItemProps {
  title?: string;
  subtitle?: string;
  filter?: string;
  date?: string;
  isPulsatingCircle?: boolean;
  isNew?: boolean;
}

export const EventAnnouncementsAlertItem: React.FC<EventAnnouncementsAlertItemProps> = ({
  title = '',
  subtitle = '',
  filter = '',
  date = '',
  isPulsatingCircle,
  isNew,
}) => {
  return (
    <List>
      <ListItem width='full' filter={filter} mx='auto' h='fit-content' position='relative'>
        <Alert
          status='success'
          height={{ base: '80px', md: '100px' }}
          width='full'
          p='0'
          dir='rtl'
          justifyContent='center'
          borderRadius='35px'
          fontSize={{ base: '12px', md: '16px' }}
          px='15px'
        >
          <Image
            height='auto'
            width='50px'
            p='5px'
            bg='green.200'
            borderRadius='100%'
            src={microphone}
            alt='Event Announcements Mic Img'
          />

          <List
            display='flex'
            width='750px'
            h='fit-content'
            justifyContent='center'
            flexDirection='column'
            textAlign='right'
            px='4'
            py='10'
          >
            <AlertTitle fontWeight='bold' data-testid='title'>
              {title}
            </AlertTitle>
            <AlertDescription data-testid='subtitle'>{subtitle}</AlertDescription>
          </List>
          <Text
            position='absolute'
            top='5'
            left='15%'
            transform='translate(-50%, -50%)'
            fontWeight='bold'
            data-testid='date'
          >
            {date}
          </Text>
        </Alert>
        {isPulsatingCircle && <PulsatingCircle />}
        {!isNew && (
          <Box
            position='absolute'
            top='0'
            left='0'
            right='0'
            bottom='0'
            zIndex='10'
            bg='gray'
            opacity='0.5'
            borderRadius='35px'
          />
        )}
      </ListItem>
    </List>
  );
};
