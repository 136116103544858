/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createContext, useContext, useReducer } from 'react';
import { WithChildren } from 'types/component';
import { GASeatsPartial } from 'types/seats';
import { CART_ACTION, cartReducer } from './helpers';
import { CartInfo, CartState } from './types';

const initialCartState: CartInfo = { tickets: [] };

export const CartContext = createContext<CartState>(initialCartState);

export const CartContextProvider = ({ children }: WithChildren) => {
  const [state, dispatch] = useReducer(cartReducer, initialCartState);

  return <CartContext.Provider value={{ ...state, dispatch }}>{children}</CartContext.Provider>;
};

export const useCartContext = () => {
  const context = useContext(CartContext);

  if (!context || !context.dispatch) {
    throw new Error('Cart context is has not been initialized!');
  }

  const clearCart = () => {
    context.dispatch!({ type: CART_ACTION.CLEAR_TICKETS });
  };

  const clearData = () => {
    context.dispatch!({ type: CART_ACTION.CLEAR_DATA });
  };

  const setMatchId = (eventInfoId: number) => {
    context.dispatch!({ type: CART_ACTION.SET_MATCH_ID, payload: { eventInfoId } });
  };

  const setEventId = (eventId: number) => {
    context.dispatch!({ type: CART_ACTION.SET_EVENT_ID, payload: { eventId } });
  };

  const setHoldToken = (holdToken: string) => {
    context.dispatch!({ type: CART_ACTION.SET_HOLD_TOKEN, payload: { holdToken } });
  };

  const updateTickets = (ga: GASeatsPartial) => {
    context.dispatch!({ type: CART_ACTION.UPDATE_TICKETS, payload: ga });
  };

  return {
    clearCart,
    clearData,
    setMatchId,
    setEventId,
    setHoldToken,
    updateTickets,
    tickets: context.tickets,
    eventInfoId: context.eventInfoId,
    eventId: context.eventId,
    holdToken: context.holdToken,
  };
};
