import * as Sentry from '@sentry/react';
import { ProtectedRoute } from 'components/ProtectedRoute/ProtectedRoute';
import { PageLayout } from 'layout/PageLayout';
import { ProfileLayout } from 'layout/ProfileLayout';
import { About } from 'pages/AboutUsPage/AboutUsPage';
import { CheckoutPage } from 'pages/CheckoutPage/CheckoutPage';
import { EventPage } from 'pages/EventPage/EventPage';
import { FeatureFlags } from 'pages/FeatureFlags/FeatureFlags';
import { OrderHistory } from 'pages/History/History';
import { HomePage } from 'pages/HomePage/HomePage';
import { ConcertsPage } from 'pages/Concerts/ConcertsPage';
import { SingleConcertPage } from 'pages/SingleConcertPage/SingleConcertPage';
import { OrderSummaryFailedPage } from 'pages/OrderSummaryFailedPage/OrderSummaryFailedPage';
import { OrderSummaryPage } from 'pages/OrderSummaryPage/OrderSummaryPage';
import { PersonalInfo } from 'pages/PersonalInfo/PersonalInfo';
import { PrivacyPolicy } from 'pages/PrivacyPolicy/PrivacyPolicy';
import { TermsOfUse } from 'pages/TermsOfUse/TermsOfUse';
import { TicketGeneratorPage } from 'pages/TicketGeneratorPage/TicketGeneratorPage';
import TagManager from 'react-gtm-module';
import { useEffect } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { ROUTES } from 'router/routes';
import { FEATURE_FLAGS_ENABLED } from 'utils/constants';

Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: import.meta.env.VITE_APP_SENTRY_TRACES_SAMPLE_RATE,
  profilesSampleRate: import.meta.env.VITE_APP_SENTRY_PROFILING_SAMPLE_RATE,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
TagManager.initialize({
  gtmId: 'GTM-WNP4D436',
});

export const App = () => (
  <SentryRoutes>
    <Route
      element={
        <PageLayout withFooter>
          <Outlet />
        </PageLayout>
      }
    >
      <Route path={ROUTES.home} element={<ConcertsPage />} />
      <Route path={ROUTES.matches} element={<HomePage />} />
      <Route path={ROUTES.concerts} element={<ConcertsPage />} />
      <Route path={ROUTES.event} element={<EventPage />} />
      <Route path={ROUTES.singleConcert} element={<SingleConcertPage />} />
      <Route path={ROUTES.checkout} element={<CheckoutPage />} />
      <Route
        path={ROUTES.summarySuccess}
        element={
          <ProtectedRoute>
            <OrderSummaryPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROUTES.summaryCancel}
        element={
          <ProtectedRoute>
            {/* TODO: create different error page if there will be design */}
            {/* <OrderSummaryCancelPage /> */}
            <OrderSummaryFailedPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROUTES.summaryFailed}
        element={
          <ProtectedRoute>
            <OrderSummaryFailedPage />
          </ProtectedRoute>
        }
      />
      <Route
        element={
          <ProfileLayout>
            <Outlet />
          </ProfileLayout>
        }
      >
        <Route
          path={ROUTES.profile}
          element={
            <ProtectedRoute>
              <PersonalInfo />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.history}
          element={
            <ProtectedRoute>
              <OrderHistory />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route path={ROUTES.privacyPolicy} element={<PrivacyPolicy />} />

      <Route path={ROUTES.termsOfUse} element={<TermsOfUse />} />
      <Route path={ROUTES.aboutUs} element={<About />} />
      {FEATURE_FLAGS_ENABLED ? (
        <Route path={ROUTES.featureflag} element={<FeatureFlags />} />
      ) : null}
      <Route path={'*'} element={<Navigate replace to={ROUTES.home} />} />
    </Route>
    <Route path={ROUTES.ticketGenerator} element={<TicketGeneratorPage />} />
  </SentryRoutes>
);
