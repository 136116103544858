import React from 'react';
import { Flex, Text, ListItem } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

interface FooterNavigationListItemProps {
  id: string;
  defaultMessage: string;
  link: string;
  linkId: string;
}

const FooterNavigationListItem: React.FC<FooterNavigationListItemProps> = ({
  id,
  defaultMessage,
  link,
  linkId,
}) => {
  return (
    <ListItem>
      <Flex
        flexDirection={{ md: 'column' }}
        justify={{ base: 'flex-end' }}
        display={{
          base: 'none',
          md: 'flex',
        }}
      >
        <Text mb='40px' textStyle={{ base: 'subtitleLarge', md: 'headlineSmall' }}>
          <FormattedMessage id={id} defaultMessage={defaultMessage} />
        </Text>
        <Link to={link}>
          <Text
            _hover={{
              color: 'green.G50',
            }}
            mb='24px'
            textStyle={{ md: 'subtitleSmall' }}
          >
            <FormattedMessage id={linkId} defaultMessage={defaultMessage} />
          </Text>
        </Link>
      </Flex>
    </ListItem>
  );
};

export default FooterNavigationListItem;
