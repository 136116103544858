import { Text } from '@chakra-ui/react';

interface QrCodeNotAvailableProps {
  statusMessage: string;
}

export const QrCodeNotAvailable = ({ statusMessage }: QrCodeNotAvailableProps) => {
  return (
    <Text textStyle='headlineSmall' color='red' px='50px'>
      {statusMessage}
    </Text>
  );
};
