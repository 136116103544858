import { tzAxios } from 'api/axios';
import { ProfileEditRequest } from 'api/requestTypes';
import { ProfileDetailsResponse } from 'api/responseTypes';

export const getProfileInfo = async () =>
  (await tzAxios.get<ProfileDetailsResponse>('/users/details')).data;

export const putProfileInfo = async (payload: ProfileEditRequest) => {
  const parsedPayload: ProfileEditRequest = {
    first_name: payload.first_name,
    last_name: payload.last_name,
    gender: payload.gender,
    birthdate: payload.birthdate,
    interests: payload.interests,
    email: payload.email || null,
  };
  return (await tzAxios.put<ProfileEditRequest>(`/users/details`, parsedPayload)).data;
};
