import { useMediaQuery } from '@chakra-ui/react';
import { SeatsioSeatingChart } from '@seatsio/seatsio-react';
import { ConcertResponse } from 'api/responseTypes';
import { useCartContext } from 'context/CartContext/CartContext';
import { useHoldTokenSessionTimerContext } from 'context/HoldTokenSessionTimerContext/HoldTokenSessionTimerContext';
import { useLanguageContext } from 'context/LanguageContext/LanguageContext';

import { ChartSeats, GASeatsPartial, SessionInitialized } from 'types/seats';
import { FEATURE_FLAGS_ENABLED } from 'utils/constants';

type HallProps = Pick<ConcertResponse, 'pricing'> & {
  event: string;
  currency: string;
};

export const Hall = ({ pricing, event, currency }: HallProps) => {
  const { clearCart, updateTickets, setHoldToken } = useCartContext();
  const [isMobileWidth] = useMediaQuery('(max-width: 400px)');
  const { resetTimer } = useHoldTokenSessionTimerContext();
  const { language } = useLanguageContext();

  const handleSectorInteraction = (ga: GASeatsPartial) => {
    updateTickets(ga);
  };

  return (
    <SeatsioSeatingChart
      language={language === 'en' && FEATURE_FLAGS_ENABLED ? 'en' : 'ar'}
      workspaceKey={import.meta.env.VITE_APP_WORKSPACE_KEY}
      event={event}
      region='eu'
      maxSelectedObjects={4}
      pricing={pricing}
      session='start'
      showFullScreenButton={isMobileWidth}
      onChartRendered={(chart: ChartSeats) => {
        clearCart();
        setHoldToken(chart.holdToken);
      }}
      onSessionInitialized={(session: SessionInitialized) => {
        resetTimer(session.expiresInSeconds);
      }}
      onHoldTokenExpired={clearCart}
      onObjectSelected={handleSectorInteraction}
      onObjectDeselected={handleSectorInteraction}
      priceFormatter={(price: string) => currency + ' ' + price}
      mode={'normal'}
    />
  );
};
