import { Box, Button, Checkbox, HStack, Select, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { Input } from 'components/Input/Input';
import { LoaderOverlay } from 'components/LoaderOverlay/LoaderOverlay';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { toast } from 'utils/toast';
import * as yup from 'yup';
import { useProfileData } from './hooks/useProfileData';
import { useProfileDataMutation } from './hooks/useProfileDataMutation';
import { Interests } from './types';
import { INVALID_EMAIL_REGEX } from 'utils/constants';

const schema = yup.object({
  firstName: yup
    .string()
    .transform((value) => value?.trim())
    .required('checkout.fieldIsRequired')
    .min(2, 'profile.firstName.minLengthError'),
  lastName: yup
    .string()
    .transform((value) => value?.trim())
    .required('checkout.fieldIsRequired')
    .min(2, 'profile.lastName.minLengthError'),
  gender: yup.string().oneOf(['Male', 'Female']).required('checkout.fieldIsRequired'),
  email: yup
    .string()
    .nullable()
    .transform((value) => (value === '' ? null : value))
    .matches(INVALID_EMAIL_REGEX, 'profile.email.invalidEmailError')
    .default(''),
  birthdate: yup.string().required('checkout.fieldIsRequired'),
  interests: yup.mixed<string[]>(),
});
export type FormValues = yup.InferType<typeof schema>;

export const PersonalInfo = () => {
  const { data, isLoading } = useProfileData();
  const { mutate } = useProfileDataMutation();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { isSubmitting, errors, isDirty },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    data &&
      reset({
        interests: data?.interests || [],
        firstName: data?.first_name,
        lastName: data?.last_name,
        birthdate: data?.birthdate,
        gender: data?.gender,
        email: data?.email || '',
      });
  }, [data, reset]);

  const onSubmit = handleSubmit((formData: FormValues) => {
    try {
      mutate(
        {
          first_name: formData.firstName,
          last_name: formData.lastName,
          gender: formData.gender,
          birthdate: formData.birthdate,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          interests: formData.interests!,
          email: formData.email,
        },
        {
          onSuccess: () => {
            toast({
              description: (
                <FormattedMessage
                  id='profile.successInfo'
                  defaultMessage={'Details updated successfully'}
                />
              ),
              status: 'success',
            });
          },
        },
      );
    } catch (error) {
      toast({
        description: (error as Error | AxiosError).message,
        status: 'error',
      });
    }
  });

  if (isLoading && !data) {
    return <LoaderOverlay />;
  }

  return (
    <Box paddingBottom='16px' textAlign={'right'} w={'100%'}>
      <Text variant='bodyLargestRegular'>
        <FormattedMessage id='profile.heading' defaultMessage={'Your profile'} />
      </Text>
      <Text mt={'12px'} textStyle='bodySmallRegular'>
        {data?.phone}
      </Text>
      <Box bg='white' m='40px auto' padding={'16px'} rounded={'16px'}>
        <Text textStyle='subtitleLarge'>
          <FormattedMessage
            id='profile.personalInfoHeader'
            defaultMessage={'Personal Information'}
          />
        </Text>
        <SimpleGrid as={'form'} onSubmit={onSubmit} padding={'16px 0'} gap={'16px'}>
          <Controller
            defaultValue=''
            control={control}
            name='firstName'
            render={({ field, fieldState: { error } }) => (
              <Input
                errorMsg={error?.message}
                label={<FormattedMessage id='profile.firstName' defaultMessage={'First Name'} />}
                id='firstNameInput'
                borderRadius={12}
                color='dark.D900'
                focusBorderColor={error ? 'red' : 'green.G500'}
                aria-invalid={Boolean(errors.firstName)}
                aria-errormessage={!errors.firstName ? undefined : 'firstNameError'}
                {...field}
              />
            )}
          />
          <Controller
            defaultValue=''
            control={control}
            name='lastName'
            render={({ field, fieldState: { error } }) => (
              <Input
                errorMsg={error?.message}
                label={<FormattedMessage id='profile.lastName' defaultMessage={'Last Name'} />}
                id='lastNameInput'
                borderRadius={12}
                color='dark.D900'
                focusBorderColor={error ? 'red' : 'green.G500'}
                aria-invalid={Boolean(errors.lastName)}
                aria-errormessage={!errors.lastName ? undefined : 'lastNameError'}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name='gender'
            render={({ field, fieldState: { error } }) => (
              <VStack align={'left'}>
                <Text textStyle='bodySmallMedium' marginBottom='-3px'>
                  {<FormattedMessage id='profile.gender' defaultMessage={'Gender'} />}
                </Text>
                <Select
                  textAlign={'right'}
                  id='genderSelect'
                  borderRadius={12}
                  color='dark.D900'
                  focusBorderColor={error ? 'red' : 'green.G500'}
                  aria-invalid={Boolean(errors.gender)}
                  aria-errormessage={!errors.gender ? undefined : 'genderError'}
                  display='flex'
                  {...field}
                >
                  <option value='Male'>
                    <FormattedMessage id='profile.gender.male' defaultMessage={'Male'} />
                  </option>
                  <option value='Female'>
                    <FormattedMessage id='profile.gender.female' defaultMessage={'Female'} />
                  </option>
                </Select>
                {error?.message && (
                  <Text textStyle='bodySmallMedium' color='red'>
                    <FormattedMessage
                      id='profile.invalidFieldMessage'
                      defaultMessage={'Field is invalid!'}
                    />
                  </Text>
                )}
              </VStack>
            )}
          />
          <Controller
            defaultValue=''
            control={control}
            name='birthdate'
            render={({ field, fieldState: { error } }) => (
              <Input
                errorMsg={error?.message}
                label={
                  <FormattedMessage id='profile.dateOfBirth' defaultMessage={'Date of Birth'} />
                }
                type='date'
                id='birthdateInput'
                borderRadius={12}
                color='dark.D900'
                focusBorderColor={error ? 'red' : 'green.G500'}
                aria-invalid={Boolean(errors.birthdate)}
                aria-errormessage={!errors.birthdate ? undefined : 'birthdateError'}
                min={'1900-01-01'}
                max={new Date().toISOString().split('T')[0]}
                {...field}
              />
            )}
          />
          <Controller
            defaultValue=''
            control={control}
            name='email'
            render={({ field, fieldState: { error } }) => (
              <Input
                errorMsg={error?.message}
                label={<FormattedMessage id='profile.email' defaultMessage={'Email'} />}
                id='emailInput'
                data-testid='emailInput'
                borderRadius={12}
                color='dark.D900'
                focusBorderColor={error ? 'red' : 'green.G500'}
                aria-invalid={Boolean(errors.email)}
                aria-errormessage={!errors.email ? undefined : 'emailError'}
                optional
                {...field}
                value={!field.value ? '' : field.value}
              />
            )}
          />
          <Text textStyle='bodySmallMedium' as={'label'}>
            {'('}
            <FormattedMessage id='profile.optional' defaultMessage={'Optional'} />
            {') '}
            <FormattedMessage id='profile.interests' defaultMessage={'Interests'} />
          </Text>
          <Controller
            name='interests'
            render={(props) => (
              <>
                <SimpleGrid gap={'12px'} columns={2} padding={'12px'}>
                  {Object.values(Interests).map((item) => (
                    <HStack justify={'end'}>
                      <Text textStyle='bodySmallMedium' as={'label'}>
                        <FormattedMessage
                          id={`profile.interests.${item}`}
                          defaultMessage={item.replaceAll('_', ' ')}
                        />
                      </Text>
                      <Checkbox
                        onChange={() => {
                          setValue(
                            'interests',
                            getValues().interests?.includes(item)
                              ? [...(getValues().interests?.filter((e) => e !== item) || [])]
                              : [...(getValues().interests || []), item],
                            { shouldDirty: true },
                          );
                        }}
                        defaultChecked={
                          data && data.interests ? data.interests.includes(item) : false
                        }
                      />
                    </HStack>
                  ))}
                </SimpleGrid>
                {props.fieldState.error?.message && (
                  <Text textStyle='bodySmallMedium' color='red'>
                    <FormattedMessage
                      id='profile.invalidFieldMessage'
                      defaultMessage={'Field is invalid!'}
                    />
                  </Text>
                )}
              </>
            )}
            control={control}
          />
          <Button
            isLoading={isSubmitting}
            type='submit'
            rounded={'16px'}
            variant='primaryLarge'
            isDisabled={!isDirty}
          >
            <FormattedMessage id='profile.save' />
          </Button>
        </SimpleGrid>
      </Box>
    </Box>
  );
};
