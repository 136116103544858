import { UnorderedList } from '@chakra-ui/react';
import { Key } from 'react';
import { EventAnnouncementsAlertItem } from 'components/EventAnnouncementsAlertItem/EventAnnouncementsAlertItem';
import { useEventAnnouncementsQuery } from '../useEventAnnouncements';

interface Announcement {
  id: Key;
  title: string;
  subtitle: string;
  createdAt: string;
}

interface EventAnnouncementsProps {
  event_id: number;
}

export const EventAnnouncements = ({ event_id }: EventAnnouncementsProps) => {
  const { data } = useEventAnnouncementsQuery(event_id);

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <UnorderedList
      listStyleType='none'
      display='flex'
      justifyContent='center'
      flexDirection='column'
      textAlign='right'
      m='0'
      spacing={3}
    >
      {data
        .slice()
        .sort((a: Announcement, b: Announcement) => {
          const createdAtA = new Date(a.createdAt).getTime();
          const createdAtB = new Date(b.createdAt).getTime();

          return createdAtB - createdAtA;
        })
        .map((announcement: Announcement, index: number) => {
          const createdAtTime = new Date(announcement.createdAt);
          const currentTime = new Date();

          const timeDifferenceInMinutes = Math.floor(
            (currentTime.getTime() - createdAtTime.getTime()) / (1000 * 60),
          );

          return (
            <EventAnnouncementsAlertItem
              key={announcement.id}
              date={`قبل ${timeDifferenceInMinutes} دقيقة`}
              title={announcement.title}
              subtitle={announcement.subtitle}
              filter={index === 0 ? 'none' : 'grayscale(100%)'}
              isNew={index === 0 ? true : false}
              isPulsatingCircle={index === 0 ? true : false}
            />
          );
        })}
    </UnorderedList>
  );
};
