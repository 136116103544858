import { Box, Button, Heading, Input, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { ModalLogin } from 'components/ModalLogin/ModalLogin';
import { ModalUnknownError } from 'pages/CheckoutPage/ModalUnknownError/ModalUnknownError';
import { useState } from 'react';
import { useSignOut } from 'react-auth-kit';
import { toast } from 'utils/toast';

export const FeatureFlags = () => {
  const [isUnknownErrorModalOpen, setIsUnknownErrorModalOpen] = useState(false);

  const [currentCountryCode, setCurrentCountryCode] = useState(
    localStorage.getItem('dialingCode') || '',
  );
  const signOut = useSignOut();
  const [newCountryCode, setNewCountryCode] = useState('');

  const handleSetCountryCode = () => {
    localStorage.setItem('dialingCode', newCountryCode);
    setCurrentCountryCode(newCountryCode);
    setNewCountryCode('');
    toast({
      status: 'success',
      description: 'Country code has been set!',
    });
  };

  const handleUnsetCountryCode = () => {
    localStorage.removeItem('dialingCode');
    setCurrentCountryCode('');
    toast({
      status: 'success',
      description: 'Country code has been unset!',
    });
  };

  const handleSignout = () => {
    signOut();
    toast({
      status: 'success',
      description: 'You have been signed out!',
    });
  };

  const handleOpenUnknownErrorModal = () => setIsUnknownErrorModalOpen(true);

  const handleCloseModal = () => {
    setIsUnknownErrorModalOpen(false);
  };
  const [loginModalVisible, setloginModalVisible] = useState(false);

  return (
    <Box padding='30px' width='100vw'>
      <Heading mb='30px'>Feature Flags 🚩</Heading>
      <SimpleGrid autoRows='200px' columns={{ base: 1, md: 2, '2xl': 4 }} gap='10px'>
        <Box bg='white' padding='16px 30px' borderRadius='16px'>
          <Text variant='subtitleLarge' mb='40px'>
            📞 Current country code is:
          </Text>
          <Text variant='bodyLargestRegular'> {currentCountryCode || '+964'}</Text>
        </Box>
        <Box bg='white' padding='16px 30px' borderRadius='16px'>
          <Text variant='subtitleLarge' mb='20px'>
            ☎️ Add custom country code
          </Text>
          <VStack align='stretch'>
            <SimpleGrid gap='20px'>
              <Input
                placeholder='+48'
                value={newCountryCode}
                onChange={(e) => setNewCountryCode(e.target.value)}
              />
              <Button
                isDisabled={!newCountryCode}
                variant='primaryLarge'
                onClick={handleSetCountryCode}
              >
                Set country code
              </Button>
            </SimpleGrid>
          </VStack>
        </Box>
        <Box bg='white' padding='16px 30px' borderRadius='16px'>
          <Text variant='subtitleLarge'>🏴 Remove custom country code</Text>
          <VStack align='stretch' justify='center' height='90%'>
            <Button onClick={handleUnsetCountryCode} variant='primaryLarge'>
              Unset country code
            </Button>
          </VStack>
        </Box>
        <Box border='1px solid red' bg='white' padding='16px 30px' borderRadius='16px'>
          <Text variant='subtitleLarge'>🔪 Force sign out</Text>
          <VStack align='stretch' justify='center' height='90%'>
            <Button
              bg={'red'}
              _hover={{ background: 'maroon', borderColor: 'maroon' }}
              borderColor={'red'}
              onClick={handleSignout}
              variant='primaryLarge'
            >
              Force sign out
            </Button>
          </VStack>
        </Box>
        <Box bg='white' padding='16px 30px' borderRadius='16px'>
          <Text variant='subtitleLarge'>Test checkout modals</Text>
          <VStack align='stretch' justify='center' height='90%'>
            <Button onClick={handleOpenUnknownErrorModal} variant='primaryMedium'>
              Unknown Error Modal
            </Button>
          </VStack>
          <ModalUnknownError isOpen={isUnknownErrorModalOpen} onClose={handleCloseModal} />
        </Box>
        <Box bg='white' padding='16px 30px' borderRadius='16px'>
          <Text variant='subtitleLarge'>Test Sentry error monitoring</Text>
          <VStack align='stretch' justify='center' height='90%'>
            <Button
              onClick={() => {
                throw Error('Test error');
              }}
              variant='primaryMedium'
            >
              Trigger FE error
            </Button>
          </VStack>
        </Box>
        <Box bg='white' padding='16px 30px' borderRadius='16px'>
          <Text variant='subtitleLarge'>Test Login Modal</Text>
          <VStack align='stretch' justify='center' height='90%'>
            <Button
              onClick={() => {
                setloginModalVisible(true);
              }}
              variant='primaryMedium'
            >
              Open
            </Button>
          </VStack>
        </Box>
        {loginModalVisible && (
          <ModalLogin
            onClose={() => {
              setloginModalVisible(false);
            }}
            isOpen={loginModalVisible}
          />
        )}
      </SimpleGrid>
    </Box>
  );
};
