import { Button, Heading, Icon, Show, Text, VStack } from '@chakra-ui/react';
import { ReactComponent as close } from 'assets/close.svg';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ROUTES } from 'router/routes';

export const OrderSummaryFailedPage = () => (
  <VStack bg='green.G700' textAlign='center' justifyContent='center' gap='16px' p='40px 16px'>
    <Icon as={close} boxSize='80px' color='red.G500' />
    <div>
      <Heading
        as='h1'
        variant={{ base: 'headingMedium', sm: 'headingLarge' }}
        mb='4px'
        color='white'
      >
        <FormattedMessage
          id='orderSummary.error.title'
          defaultMessage='Error during placing an order'
        />
      </Heading>
      <Text textStyle='bodyLargeRegular' color='white'>
        <FormattedMessage
          id='orderSummary.error.descriptionFirstLine'
          defaultMessage='Unknown error happened during placing an order.'
        />
      </Text>
      <Show above='md'>
        <Text textStyle='bodyLargeRegular' color='white' mt='0px'>
          <FormattedMessage
            id='orderSummary.error.descriptionSecondLine'
            defaultMessage='Please try again later'
          />
        </Text>
      </Show>
    </div>
    <Button
      variant='primaryMedium'
      bg='green.G0'
      borderColor='green.G0'
      _hover={{ bg: 'green.G200', borderColor: 'green.G200' }}
      textColor='dark.D900'
      as={Link}
      to={ROUTES.home}
    >
      <FormattedMessage id='orderSummary.error.button' defaultMessage='Back to homepage' />
    </Button>
  </VStack>
);
