import { HStack, VStack, Text, IconButton, Tooltip } from '@chakra-ui/react';
import { ReactComponent as Trash } from 'assets/trash.svg';
import { FormattedMessage, useIntl } from 'react-intl';

interface TicketProps {
  title: string;
  price: string;
  category: string;
  onDeleteClick: () => void;
}

export const Ticket = ({ title, price, category, onDeleteClick }: TicketProps) => {
  const { formatMessage } = useIntl();

  return (
    <HStack p='24px' justify='space-between' bg='dark.D0' borderRadius={16}>
      <VStack>
        <Tooltip
          hasArrow
          label={formatMessage({
            id: 'orderSummary.deleteTicket',
            defaultMessage: 'Delete ticket',
          })}
          aria-label='delete ticket'
          bg='red'
          borderRadius='5px'
        >
          <IconButton
            bg='none'
            borderRadius='50%'
            _hover={{
              bg: 'dark.D100',
            }}
            aria-label='remove selected ticket'
            onClick={onDeleteClick}
            icon={<Trash height='13px' width='20px' />}
          />
        </Tooltip>
      </VStack>
      <VStack textAlign='right' align='stretch' flex='1' pl='10px'>
        <HStack justify='space-between'>
          <Text variant='bodyLargeSemiBold'>{title}</Text>
          <Text variant='bodySmallRegular'>
            <FormattedMessage id='orderSummary.sector' defaultMessage={'Sector'} />
          </Text>
        </HStack>
        <HStack justify='space-between'>
          <Text>{category}</Text>
          <Text variant='bodySmallRegular'>
            <FormattedMessage id='orderSummary.category' defaultMessage={'Category'} />
          </Text>
        </HStack>
        <HStack justify='space-between'>
          <Text variant='bodyLargeSemiBold'>{price}</Text>
          <Text variant='bodySmallRegular'>
            <FormattedMessage id='checkout.ticketPrice' defaultMessage={'Ticket price'} />
          </Text>
        </HStack>
      </VStack>
    </HStack>
  );
};
