import { Image, Text, HStack, Button, VStack, Divider, Flex } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import walletSVG from 'assets/instructions-wallet.svg';
import cardSVG from 'assets/instructions-card.svg';
import formSVG from 'assets/instructions-form.svg';
import { Link } from 'react-router-dom';
import { QI_URL } from 'utils/constants';

export const OpenQiCardInstructionsContent = () => {
  return (
    <Flex flexDirection='column' px={{ base: '16px', md: '32px' }} py='40px' align='end' gap='24px'>
      <Text
        variant='bodyLargestRegular'
        pb={{ base: '20px', md: '16px' }}
        textAlign={{ base: 'center', md: 'end' }}
      >
        <FormattedMessage
          id='checkout.modal.openQiCardAndGetTicket'
          defaultMessage={'Open Qi card and get free ticket to the match'}
        />
      </Text>
      <HStack spacing={{ base: '20px', md: '36px' }} pr={{ base: '0px', md: '12px' }}>
        <VStack align='end' spacing='16px'>
          <Text variant='bodyLargestRegular' color='green.G500'>
            <FormattedMessage
              defaultMessage='Open <color>Qi</color> account'
              id='checkout.modal.openQiAccount'
              values={{
                color: (chunks) => (
                  <Text as='span' color='yellow.Y500'>
                    {chunks}
                  </Text>
                ),
              }}
            />
          </Text>
          <Text variant='bodySmallRegular'>
            <FormattedMessage
              id='checkout.modal.openQiAccountDescription'
              defaultMessage={
                'Visit Qi Website. Select Iraqi Football Association from drop down list'
              }
            />
          </Text>
          <Link
            aria-label='Open Qi account link'
            to={QI_URL}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button aria-label='Open Qi account button' variant='primaryMedium'>
              <FormattedMessage
                defaultMessage='Open Qi Card'
                id='checkout.modal.openQiAccountButton'
              />
            </Button>
          </Link>
        </VStack>
        <Image src={cardSVG} alt='icon representing credit card' />;
      </HStack>

      <Divider />

      <HStack spacing={{ base: '20px', md: '36px' }} pr={{ base: '0px', md: '12px' }}>
        <VStack align='end' spacing='16px'>
          <Text variant='bodyLargestRegular' color='green.G500'>
            <FormattedMessage
              defaultMessage='Register <color>Qi</color> card'
              id='checkout.modal.registerQiCard'
              values={{
                color: (chunks) => (
                  <Text as='span' color='yellow.Y500'>
                    {chunks}
                  </Text>
                ),
              }}
            />
          </Text>
          <Text variant='bodySmallRegular'>
            <FormattedMessage
              id='checkout.modal.registerQiCardDescription'
              defaultMessage={
                'Select Iraqi Football Association from drop down list, then choose the image of your favorite club.'
              }
            />
          </Text>
        </VStack>
        <Image src={formSVG} alt='icon representing blank form' />
      </HStack>

      <Divider />

      <HStack spacing={{ base: '20px', md: '36px' }} pr={{ base: '0px', md: '12px' }} pb='16px'>
        <VStack align='end' spacing='16px'>
          <Text variant='bodyLargestRegular' color='green.G500'>
            <FormattedMessage
              id='checkout.modal.experienceSeamlessPayment'
              defaultMessage={'Experience seamless payment'}
            />
          </Text>
          <Text variant='bodySmallRegular'>
            <FormattedMessage
              id='checkout.modal.experienceSeamlessPaymentDescription'
              defaultMessage={
                'Fill your information and be ready to receive the card at your doorstep.'
              }
            />
          </Text>
        </VStack>
        <Image src={walletSVG} alt='icon representing wallet' />
      </HStack>
    </Flex>
  );
};
