import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import limitSVG from 'assets/ticket-info-limit.svg';
import qiIMG from 'assets/ticket-info-qi.webp';
import qrSVG from 'assets/ticket-info-qr.svg';
import { FormattedMessage } from 'react-intl';

export const PleaseNote = () => (
  <VStack spacing='16px' align='stretch' bg='dark.D0' p='16px 24px'>
    <Text textAlign='right' textStyle='subtitleLarge' mb='8px'>
      <FormattedMessage id='eventpage.ticketInfo.pleaseNote' defaultMessage={'Please note that:'} />
    </Text>

    <HStack justify='end' spacing='28px' align='center' pr='10px'>
      <VStack spacing='4px' align='end'>
        <Text textAlign='right' textStyle='bodySmallSemibold'>
          <FormattedMessage id='eventpage.ticketInfo.noQiCard' defaultMessage={'No Qi card?'} />
        </Text>
        <Text textAlign='right' textStyle='bodySmallRegular' color='dark.D500'>
          <FormattedMessage
            id='eventpage.ticketInfo.openFreeAccount'
            defaultMessage={'Open free account within 5 mins'}
          />
        </Text>
        <Text
          textAlign='right'
          as='a'
          href='https://aam.qi.iq/'
          target='_blank'
          textStyle='buttonMedium'
          color='green.G500'
        >
          <FormattedMessage
            id='eventpage.ticketInfo.howToRegister'
            defaultMessage={'Learn how to register'}
          />
        </Text>
      </VStack>
      <Image src={qiIMG} alt='qi logo' boxSize='28px' />
    </HStack>

    <HStack justify='end' spacing='28px' align='center' pr='10px'>
      <VStack spacing='4px' align='end'>
        <Text textAlign='right' textStyle='bodySmallSemibold' color='dark.9500'>
          <FormattedMessage
            id='eventpage.ticketInfo.needMobilePhone'
            defaultMessage={'You would need a mobile phone'}
          />
        </Text>
        <Text textAlign='right' textStyle='bodySmallRegular' color='dark.D500'>
          <FormattedMessage
            id='eventpage.ticketInfo.smartphoneToScan'
            defaultMessage={'You’ll need an smartphone to scan your tickets at the gate'}
          />
        </Text>
      </VStack>
      <Image src={qrSVG} alt='qr code' boxSize='28px' />
    </HStack>

    <HStack justify='end' spacing='28px' align='center' pr='10px'>
      <VStack spacing='4px' align='end'>
        <Text textAlign='right' textStyle='bodySmallSemibold' color='dark.9500'>
          <FormattedMessage
            id='eventpage.ticketInfo.ticketLimit'
            defaultMessage={'Event ticket limit: 4'}
          />
        </Text>
        <Text textAlign='right' textStyle='bodySmallRegular' color='dark.D500'>
          <FormattedMessage
            id='eventpage.ticketInfo.purchase4tickets'
            defaultMessage={'You cant purchase more than 4 tickets at the same time'}
          />
        </Text>
      </VStack>
      <Image src={limitSVG} alt='icon representing limit of 4 tickets' boxSize='28px' />
    </HStack>
  </VStack>
);
