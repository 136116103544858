const size = {
  medium: {
    height: '30px',
    borderRadius: '3xl',
    borderWidth: '2px',
    lineHeight: '1.5rem',
  },
};

export const badgeStyle = {
  primary: {
    ...size.medium,
    borderColor: '#F0F8F7',
    bg: '#F0F8F7',
    color: '#008674',
  },
  red: {
    ...size.medium,
    borderColor: '#FEEFEF',
    bg: '#FEEFEF',
    color: '#E85858',
    textTransform: 'none',
  },
};
