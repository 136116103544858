import { VStack } from '@chakra-ui/react';

import QRCode from 'react-qr-code';

interface QrCodeValidProps {
  idToEncode: string;
}

export const QrCodeValid = ({ idToEncode }: QrCodeValidProps) => {
  return (
    <VStack>
      <QRCode
        size={200}
        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
        value={idToEncode}
        viewBox={`0 0 120 120`}
      />
    </VStack>
  );
};
