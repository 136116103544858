import { getEventInfo } from 'api/eventpage';
import { useQuery } from 'react-query';

export const useConcertQuery = (id?: string) => {
  if (!id) {
    throw Error('No event id found!');
  }

  const { isFetching, data, isError } = useQuery(
    `concert-${id}`,
    async () => await getEventInfo(id),
    {
      cacheTime: 1000 * 60 * 30,
    },
  );

  return {
    isFetching,
    data,
    isError,
  };
};
