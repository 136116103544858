/* eslint-disable react-hooks/exhaustive-deps */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback, useLayoutEffect } from 'react';
import { useAuthHeader } from 'react-auth-kit';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'router/routes';
import { toast } from 'utils/toast';

import { apiRefresh } from './auth/index';

export const tzAxios = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_API,
});

export const useInterceptors = () => {
  const authHeader = useAuthHeader();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const handleResponseSuccess = (response: AxiosResponse) => response;

  const handleErrorCodes = useCallback(async (error: AxiosError<{ message: string }>) => {
    if (
      error?.response?.status === 500 ||
      error?.response?.status === 404 ||
      error?.response?.status === 403
    ) {
      navigate(ROUTES.home);
      toast({
        title: formatMessage({
          id: 'global.error.generictitle',
          defaultMessage: 'Something went wrong!',
        }),
        description: error.response.data.message || error.message,
        status: 'error',
      });
    }

    if (error?.response?.status === 401) {
      handle401Error(error);
    }

    if (error?.response?.status === 429) {
      toast({
        title: formatMessage({
          id: 'global.error.generictitle',
          defaultMessage: 'Something went wrong!',
        }),
        description: error.response.data.message || error.message,
        status: 'error',
      });
    }
  }, []);

  useLayoutEffect(() => {
    tzAxios.defaults.headers.common['X-Custom-Language'] = 'ar';

    tzAxios.defaults.headers.common.Authorization = authHeader();

    queryClient.invalidateQueries();

    const unexpectedErrorInterceptor = tzAxios.interceptors.response.use(
      handleResponseSuccess,
      (error) => {
        handleErrorCodes(error);
        throw error;
      },
    );

    return () => {
      tzAxios.interceptors.response.eject(unexpectedErrorInterceptor);
    };
  }, [authHeader, queryClient, handleErrorCodes]);
};

async function handle401Error(error: any) {
  const refreshToken = localStorage.getItem('_auth_refresh');
  if (refreshToken) {
    try {
      const accessToken = await refreshAccessToken(refreshToken);
      if (accessToken) {
        updateAccessTokenAndHeaders(accessToken, error.config);
        if (!(error.config.url && error.config.url.endsWith('/checkout/'))) {
          location.reload();
          return tzAxios(error.config as AxiosRequestConfig);
        }
        return tzAxios(error.config as AxiosRequestConfig);
      }
    } catch (err) {
      console.log(err);
    }
  }
}
async function refreshAccessToken(refreshToken: string) {
  const data = { refreshToken: refreshToken };
  const response = await apiRefresh(data);
  return response.accessToken;
}

function updateAccessTokenAndHeaders(accessToken: string, config: any) {
  localStorage.setItem('_auth', accessToken);
  tzAxios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  if (config && config.headers) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
}
