import { Box, Button, Text, VStack } from '@chakra-ui/react';
import { ReactComponent as Chevron } from 'assets/go-back.svg';
import { useCartContext } from 'context/CartContext/CartContext';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export const Header = () => {
  const navigate = useNavigate();
  const { eventInfoId } = useCartContext();
  return (
    <Box bg='green.G700' color='white' h={{ base: '280', md: '321' }} px={4}>
      <Box maxW='container.xl' mx='auto' py={{ base: '20px', md: '40px' }} px={{ base: 0, md: 4 }}>
        <VStack alignItems='stretch' textAlign='end' spacing='0px'>
          <Button
            variant='ghostWhiteNoPaddingMedium'
            width='fit-content'
            alignSelf='end'
            rightIcon={<Chevron />}
            onClick={() => navigate(`/event/${eventInfoId}`)}
          >
            <FormattedMessage id='checkout.toPlaceMap' defaultMessage={'To the place map'} />
          </Button>
          <VStack alignItems='end' m={0}>
            <Text textStyle='headlineLarge'>
              <FormattedMessage
                id='checkout.finalStepTitle'
                defaultMessage={'Final step, make the payment'}
              />
            </Text>
            <Text textStyle='bodyLargeRegular' color='green.G50'>
              <FormattedMessage
                id='checkout.finalStepSubtitle'
                defaultMessage={
                  ' To purchase the ticket, you need to provide ticket holder and debit card details.'
                }
              />
            </Text>
          </VStack>
        </VStack>
      </Box>
    </Box>
  );
};
