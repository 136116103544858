export const colors = {
  green: {
    G900: '#005E51',
    G800: '##006B5D',
    G700: '#007968',
    G500: '#008674',
    G400: '#1A9282',
    G300: '#4DAA9E',
    G200: '#66B6AC',
    G100: '#80C3BA',
    G50: '#B3DBD5',
    G0: '#F0F8F7',
  },
  dark: {
    D900: '#282E2E',
    D800: '#2C3333',
    D700: '#414747',
    D600: '#565C5C',
    D500: '#6B7070',
    D400: '#808585',
    D300: '#808585',
    D200: '#C0C2C2',
    D150: '#DFE1E1',
    D100: '#EAEBEB',
    D0: '#F9F9F9',
  },
  yellow: {
    Y700: '#F0B318',
    Y600: '#F9CC59',
    Y500: '#FEDB5F',
    Y400: '#FEE68F',
    Y300: '#FFEDAF',
    Y200: '#FFF4CF',
    Y150: '#DFE1E1',
  },
  BG100: '#F6F7F2',
  red: '#D73B3B',
  white: '#FFFFFF',
  offwhite: '#FCFCFD',
};
