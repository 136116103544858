import { useEffect, useState } from 'react';

export const useTimer = (
  initialTime: number,
): { timer: number; resetTimer: (seconds: number) => void } => {
  const [timer, setTimer] = useState<number>(initialTime);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  const resetTimer = (seconds: number) => {
    setTimer(seconds);
  };

  return { timer, resetTimer };
};
