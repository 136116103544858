import { Spinner, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

export const QrCodeLoading = () => {
  return (
    <>
      <Spinner color='green.G500' size='xl' thickness='3px' />
      <Text textStyle='subtitleLarge'>
        <FormattedMessage
          id='ticketGenerator.generatingTheTicket'
          defaultMessage={'Generating the ticket'}
        />
      </Text>
    </>
  );
};
