import { getOrderSummary } from 'api/summarypage/index';
import { useQuery } from 'react-query';

export const useOrderSummaryQuery = (id?: string) => {
  if (!id) {
    throw Error('No order id found!');
  }

  const { isFetching, data, isError } = useQuery(id, async () => await getOrderSummary(id));

  return {
    isFetching,
    data,
    isError,
  };
};
