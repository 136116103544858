import { pxToRem } from './pxToRem';

const size = {
  large: {
    fontSize: pxToRem(15),
    lineHeight: pxToRem(24),
    fontWeight: '600',
    height: '48px',
    borderRadius: '3xl',
    borderWidth: '2px',
  },
  medium: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(22),
    fontWeight: '600',
    height: '38px',
    borderRadius: '3xl',
    borderWidth: '2px',
  },
};
const type = {
  primary: {
    bg: 'green.G500',
    color: 'white',
    borderColor: 'green.G500',
    _hover: {
      bg: 'green.G700',
      borderColor: 'green.G700',
    },
    _active: {
      bg: 'green.G900',
      borderColor: 'green.G300',
    },
    _disabled: {
      pointerEvents: 'none',
    },
  },
  secondary: {
    color: 'green.G500',
    borderColor: 'green.G500',
    _hover: {
      bg: 'green.G0',
      borderColor: 'green.G500',
    },
    _active: {
      bg: 'green.G50',
      color: 'green.G700',
      borderColor: 'green.G700',
    },
  },
  brand: {
    bg: 'yellow.Y500',
    color: 'green.G500',
    borderColor: 'yellow.Y500',
    _hover: {
      bg: 'yellow.Y400',
      borderColor: 'yellow.Y400',
    },
    _active: {
      bg: 'yellow.Y600',
      borderColor: 'yellow.Y600',
    },
  },
  ghost: {
    color: 'green.G500',
    borderColor: 'transparent',
    _hover: { bg: 'green.G0', borderColor: 'green.G0' },
    _active: { bg: 'green.G0', borderColor: 'green.G0', color: 'green.G700' },
  },
  ghostNoPadding: {
    color: 'green.G500',
    borderColor: 'transparent',
    span: {
      svg: {
        stroke: 'green.G500',
      },
    },
    _hover: {
      color: 'green.G700',
      span: {
        svg: {
          stroke: 'green.G700',
        },
      },
    },
    _active: { color: 'green.G900' },
  },
  ghostWhite: {
    color: 'white',
    borderColor: 'transparent',
    _hover: { bg: 'rgba(255, 255, 255, .1)' },
    _active: { bg: 'rgba(255, 255, 255, .2)' },
  },
  ghostWhiteNoPadding: {
    color: 'white',
    borderColor: 'transparent',
    span: {
      svg: {
        stroke: 'white',
      },
    },
    _active: {
      color: 'green.G100',
      span: {
        svg: {
          stroke: 'green.G100',
        },
      },
    },
    _hover: {
      color: 'green.G50',
      span: {
        svg: {
          stroke: 'green.G50',
        },
      },
    },
  },
};

export const buttonStyle = {
  primaryLarge: {
    ...size.large,
    ...type.primary,
  },
  primaryMedium: {
    ...size.medium,
    ...type.primary,
  },
  secondaryLarge: {
    ...size.large,
    ...type.secondary,
  },
  secondaryMedium: {
    ...size.medium,
    ...type.secondary,
  },
  brandLarge: {
    ...size.large,
    ...type.brand,
  },
  brandMedium: {
    ...size.medium,
    ...type.brand,
  },
  ghostLarge: {
    ...size.large,
    ...type.ghost,
  },
  ghostMedium: {
    ...size.medium,
    ...type.ghost,
  },
  ghostNoPaddingLarge: {
    ...size.large,
    ...type.ghostNoPadding,
  },
  ghostNoPaddingMedium: {
    ...size.medium,
    ...type.ghostNoPadding,
  },
  ghostWhiteLarge: {
    ...size.large,
    ...type.ghostWhite,
  },
  ghostWhiteMedium: {
    ...size.medium,
    ...type.ghostWhite,
  },
  ghostWhiteNoPaddingLarge: {
    ...size.large,
    ...type.ghostWhiteNoPadding,
  },
  ghostWhiteNoPaddingMedium: {
    ...size.medium,
    ...type.ghostWhiteNoPadding,
  },
};
