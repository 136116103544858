import { Box, Flex, Heading, Image, Text, VStack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import about from 'assets/about.webp';
import { PartnerCard } from './PartnerCard/PartnerCard';
import SponsorImage from './SponsorImage/SponsorImage';
import { partnerCards, sponsorItems } from './constants';

export const About = () => {
  return (
    <Box as='div' width='100vw'>
      <Box
        sx={{
          display: {
            base: 'block',
            md: 'none',
          },
        }}
        bg='green.G700'
        color='white'
      >
        <Box
          maxW='container.xl'
          mx='auto'
          px={{ base: '16px', md: '100px' }}
          py={{ base: '20px', md: '40px' }}
        >
          <VStack alignItems='stretch' textAlign='end' spacing='0px'>
            <VStack alignItems='end' m={0}>
              <Text textStyle='headlineLarge'>
                <FormattedMessage id='about.heading' defaultMessage={'About us'} />
              </Text>
              <Text textStyle='bodySmallRegular'>
                <FormattedMessage
                  id='about.subheading'
                  defaultMessage={'Our goal is to help people find fun and inspiring events.'}
                />
              </Text>
            </VStack>
          </VStack>
        </Box>
      </Box>
      <Box
        as='section'
        px={{ base: '16px', md: '48px', lg: '72px', xl: '132px' }}
        py='36px'
        textAlign='end'
        aria-labelledby='about-us'
        mx='auto'
      >
        <Flex
          gap={{
            base: '24px',
            md: '48px',
            lg: '96px',
            xl: '160px',
          }}
          flexDirection={{
            base: 'column',
            md: 'row',
          }}
          maxW='container.xl'
          mx='auto'
          justify='center'
          justifyContent='space-between'
          alignItems='center'
          mb={16}
        >
          <Image
            alt='a ticket'
            minW={{ base: '210px', md: '240px', lg: '400px' }}
            src={about}
            w={{
              base: '55%',
              md: '33%',
            }}
          />
          <Flex flexDirection='column' justifyContent='center'>
            <Text variant='bodyLargeSemiBold' mb={2}>
              <FormattedMessage id='about.heading' defaultMessage='About us' />
            </Text>
            <Heading mb={6} as='h1' variant='headlineLarge'>
              <FormattedMessage
                id='about.subheading'
                defaultMessage='Our goal is to help people find fun and inspiring events.'
              />
            </Heading>
            <Text mb={4} variant='bodySmallRegular'>
              <FormattedMessage
                id='about.content1'
                defaultMessage='As part of our social responsibility, Qi Group supports sports in Iraq through various initiatives that benefit local clubs, national teams, and sports infrastructure. Digital Zone, a Qi Group company, developed a comprehensive platform that streamlines the marketing and promotion of all sports events, making it easier for fans to stay up-to-date and purchase tickets online.'
              />
            </Text>
            <Text variant='bodySmallRegular'>
              <FormattedMessage
                id='about.content2'
                defaultMessage='With over 15,000 point of sale, fans can also purchase tickets through Qi Card agents across all regions of Iraq. We are proud to make a positive impact on the communities we serve and inspire more people to engage with sports in Iraq.'
              />
            </Text>
          </Flex>
        </Flex>
        <Flex w='100%' flexDirection='column' justifyContent='center' alignItems='center' mb={16}>
          <Heading variant='headlineLarge' mb='40px'>
            <FormattedMessage id='about.ourSponsors' defaultMessage='Our Sponsors' />
          </Heading>
          <Flex
            justifyContent='space-between'
            gap={{
              base: '36px',
              md: 32,
            }}
          >
            {sponsorItems.map((sponsor, index) => (
              <SponsorImage
                key={index}
                src={sponsor.src}
                content={sponsor.content}
                header={sponsor.header}
                defaultHeaderMessage={sponsor.defaultHeaderMessage}
                defaultContentMessage={sponsor.defaultContentMessage}
              />
            ))}
          </Flex>
        </Flex>
        <Flex flexDirection='column' justifyContent='center' alignItems='center' mb={16}>
          <Heading variant='headlineLarge' mb='40px'>
            <FormattedMessage id='about.ourPartners' defaultMessage='Our Sponsors' />
          </Heading>
          <Flex
            flexDirection={{
              base: 'column',
              md: 'row',
            }}
            gap={{
              base: '48px',
              md: '24px',
            }}
          >
            {partnerCards.map((partner, index) => (
              <PartnerCard
                key={index}
                img={partner.img}
                alt={partner.alt}
                header={partner.header}
                content={partner.content}
                defaultHeaderMessage={partner.defaultHeaderMessage}
                defaultContentMessage={partner.defaultContentMessage}
              />
            ))}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
