export enum ROUTES {
  home = '/',
  event = 'event/:matchId/:eventId',
  checkout = 'checkout',
  summarySuccess = 'summary/success',
  summaryCancel = 'summary/cancel',
  summaryFailed = 'summary/failed',
  featureflag = 'featureflags',
  ticketGenerator = 'ticket-generator/:ticketId',
  termsOfUse = 'terms-of-use',
  privacyPolicy = 'privacy-policy',
  aboutUs = 'about-us',
  profile = 'profile',
  history = 'history',
  concerts = 'concerts',
  singleConcert = 'concert/:concertId/:eventId',
  matches = '/matches',
}
