// import getUserLocale from 'get-user-locale';
import { createContext, useContext, useState } from 'react';
import { IntlProvider } from 'react-intl';
import arMessages from 'translations/ar.json';
import enMessages from 'translations/en.json';
import { WithChildren } from 'types/component';
import { FEATURE_FLAGS_ENABLED } from 'utils/constants';

const defaultLocale = 'ar-iq';
// const userLocale = getUserLocale({ useFallbackLocale: false });
// const localeToApply = userLocale || defaultLocale;
// const languageFromLocale = localeToApply.split(/[-_]/)[0];
const getMessages = (language: string) =>
  language === 'en' && FEATURE_FLAGS_ENABLED ? enMessages : arMessages;

interface LanguageContextProps {
  language: string;
  selectLanguage: (newLocale: string) => void;
}

export const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

export const LanguageProvider = ({ children }: WithChildren) => {
  const [language, setLanguage] = useState('ar');

  const selectLanguage = (newLocale: string) => {
    setLanguage(newLocale);
  };

  return (
    <LanguageContext.Provider value={{ language, selectLanguage }}>
      <IntlProvider messages={getMessages('ar')} defaultLocale={defaultLocale} locale={'ar'}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () => {
  const context = useContext(LanguageContext);

  if (context === undefined) {
    throw new Error('LanguageContext is undefined');
  }

  return { ...context };
};
