import { Box, Divider, Flex, Icon, Image, Show, Text } from '@chakra-ui/react';
import { ReactComponent as facebookSVG } from 'assets/facebook-circled.svg';
import { ReactComponent as instagramSVG } from 'assets/instagram.svg';
import { ReactComponent as linkedInSVG } from 'assets/linkedIn.svg';
import logo from '../../assets/logo-new.svg';
import { FormattedMessage } from 'react-intl';
import { ROUTES } from 'router/routes';
import { FB_URL, INSTAGRAM_URL, LINKEDIN_URL, SUPPORT_EMAIL, SUPPORT_TEL } from 'utils/constants';
import FooterNavigation from './FooterNavigation/FooterNavigation';
import { Sponsors } from 'components/Sponsors/Sponsors';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <Box
      as='footer'
      bg='green.G900'
      color='green.G0'
      py={{ base: 5, md: 10 }}
      px={4}
      textAlign='right'
      width='100vw'
    >
      <Show below='lg'>
        <Box mb={'30px'} alignSelf='end' px={'2px'}>
          <Sponsors />
        </Box>
      </Show>
      <Flex flexDirection='column' maxW='container.xl' mx='auto' mt={{ base: 0, md: 4 }}>
        <Flex justifyContent='space-between'>
          <FooterNavigation />
          <Flex
            alignItems={{ base: 'flex-end' }}
            justifyContent='space-between'
            flexDirection={{ base: 'column-reverse' }}
            gap={6}
          >
            <Flex>
              <Text textStyle={{ base: 'subtitleLarge', md: 'headlineSmall' }}>
                <FormattedMessage
                  id='footer.madeSimple'
                  defaultMessage={'Online ticketing made simple'}
                />
              </Text>
            </Flex>
            <Flex alignItems='center' justifyContent='right'>
              <Image
                src={logo}
                w={{ base: '125px', md: '120px' }}
                h={{ base: '32px', md: '62px' }}
                alt='ticket zone logo'
              />
            </Flex>
          </Flex>
        </Flex>

        <Divider
          borderColor={{ base: 'white', md: '#078977' }}
          mt={{ base: 6, md: 6 }}
          mb={{ base: 3, md: 10 }}
        />

        <Flex
          justifyContent='space-between'
          flexDirection={{ base: 'column-reverse', md: 'row' }}
          mb={3}
        >
          <Flex gap={5} alignSelf={{ base: 'flex-end', md: 'center' }} my={{ base: 6, md: 0 }}>
            <Link aria-label='Facebook' to={FB_URL} target='_blank' rel='noopener noreferrer'>
              <Icon as={facebookSVG} boxSize={6} color='green.G50' />
            </Link>
            <Link aria-label='LinkedIn' to={LINKEDIN_URL} target='_blank' rel='noopener noreferrer'>
              <Icon as={linkedInSVG} boxSize={6} color='green.G50' />
            </Link>
            <Link
              aria-label='Instagram'
              to={INSTAGRAM_URL}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Icon as={instagramSVG} boxSize={6} color='green.G50' />
            </Link>
          </Flex>
          <Flex
            justifyContent='flex-end'
            alignItems={{ base: 'flex-end', lg: 'center' }}
            flexDirection={{ base: 'column', md: 'row' }}
            gap={4}
          >
            <Link to={ROUTES.termsOfUse} style={{ color: '#b3dbd5', fontWeight: 'bold' }}>
              <FormattedMessage id='footer.terms' defaultMessage={'Terms of Use'} />
            </Link>
            <Link to={ROUTES.privacyPolicy} style={{ color: '#b3dbd5', fontWeight: 'bold' }}>
              <FormattedMessage id='footer.privacy' defaultMessage={'Privacy policy'} />
            </Link>
            <Text color='green.G50' textStyle='bodySmallMedium' mt={{ base: 3, md: 0 }}>
              <FormattedMessage
                id='footer.copy'
                defaultMessage={'Copyright © 2023 Digitalzone. All rights reserved'}
              />
            </Text>
          </Flex>
        </Flex>
        <Flex flexDirection='column' alignItems='flex-start' gap={1}>
          <Flex gap={1}>
            <a href='tel:6355' style={{ color: '#b3dbd5', fontWeight: 'bold' }}>
              {SUPPORT_TEL}
            </a>
            <Text color='green.G50' textStyle='bodySmallMedium' mt={{ base: 3, md: 0 }}>
              <FormattedMessage id='footer.callCenterNumber' defaultMessage='Call Center Number' />:
            </Text>
          </Flex>
          <Flex gap={1}>
            <a href='mailto: info@digitalzone.app' style={{ color: '#b3dbd5', fontWeight: 'bold' }}>
              {SUPPORT_EMAIL}
            </a>
            <Text color='green.G50' textStyle='bodySmallMedium' mt={{ base: 3, md: 0 }}>
              <FormattedMessage id='footer.email' defaultMessage='Email' />:
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

// test
