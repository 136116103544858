import { Box, Flex, Show, Text, useBreakpoint } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { WithChildren } from 'types/component';
import { ProfileNavigation } from './ProfileNavigation';

export const ProfileLayout = ({ children }: WithChildren) => {
  const { pathname } = useLocation();
  const breakpoint = useBreakpoint();
  const navVariant = ['base', 'sm', 'md'].includes(breakpoint) ? 'mobile' : 'desktop';

  return (
    <>
      <Box as='section' bg='green.G700'>
        <Flex
          maxW='container.xl'
          mx='auto'
          align='end'
          textAlign='right'
          direction='column'
          gap={1}
          py={10}
          px={4}
        >
          <Text color={'white'} textStyle={{ base: 'headlineMedium', md: 'headlineLarge' }}>
            <FormattedMessage id='profile.yourAccount' defaultMessage={'Your Account'} />
          </Text>
          <Text color={'white'} variant='bodyLargeRegular'>
            <FormattedMessage
              id='profile.subtitle'
              defaultMessage={'All information about you and your purchases in one place'}
            />
          </Text>
        </Flex>
      </Box>
      <Box
        maxW='container.xl'
        mx='auto'
        w='100%'
        gap={1}
        py={{ base: 4, lg: 10 }}
        px={4}
        display='grid'
        gridTemplateColumns={{ base: '1fr', lg: '5fr 2fr' }}
      >
        <Show below='lg'>
          <ProfileNavigation currentPathname={pathname} variant={navVariant} />
        </Show>
        {children}
        <Show above='lg'>
          <ProfileNavigation currentPathname={pathname} variant={navVariant} />
        </Show>
      </Box>
    </>
  );
};
