import { Badge, Box, Divider, Flex, Image, Text, useMediaQuery } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as mapPinSVG } from 'assets/map-pin.svg';
import { EventType } from 'api/responseTypes';

interface HistoryCardProps {
  item: {
    currency: string;
    date: string;
    eventid: string;
    id: number;
    stadium: string;
    startingPrice: number;
    subtitle: string;
  };
  event: {
    id: number;
    eventType: EventType;
  };
  teamA: {
    id: string;
    name: string;
    logo: string;
  };
  teamB: {
    id: string;
    name: string;
    logo: string;
  };
  time: string;
  orderNumber: string;
}

export const HistoryCard = ({ item, event, teamA, teamB, time, orderNumber }: HistoryCardProps) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Box
      bg={'white'}
      color='dark.D500'
      textAlign='right'
      width={isMobile ? 'full' : '400px'}
      borderRadius='16px'
      borderColor='white'
      borderWidth='1px'
      _hover={{ borderColor: 'green.G500' }}
      pointerEvents='unset'
      as='li'
      tabIndex={0}
      transition='border-color .2s ease'
      aria-labelledby='gameCardTitle team1 team2'
      m='10px'
      listStyleType={'none'}
    >
      <Flex flexDirection='column' py='2px'>
        <Flex flexDirection='column' alignItems={'center'} justify='center' py='4px'>
          <div>{event.eventType}</div>
          <div>{orderNumber}</div>
        </Flex>
      </Flex>
      <Flex justifyContent='space-around' flexDirection='row' textAlign='center'>
        <Flex justifyContent='center' alignItems='center' flexDirection='column' py='4px'>
          <Image
            src={teamA.logo}
            alt={`${teamA.name} logo`}
            boxSize='56px'
            borderRadius='50%'
            height='56px'
            width='56px'
          />
          <Text id='team1' textStyle='caption'>
            {teamA.name}
          </Text>
        </Flex>
        <Flex justifyContent='center' alignItems='center' flexDirection='column' py='4px'>
          <Text textStyle='bodySmallSemibold'>{item.date}</Text>
          <Text textStyle='caption' color='dark.D500'>
            {time}
          </Text>
          <Flex gap={1} alignItems='center' justifyContent={'center'}>
            <Text textStyle='bodySmallRegular' color='green.G500'>
              {item.stadium}
            </Text>
            <Image as={mapPinSVG} alt='icon representing a pin on a map' boxSize={4} />
          </Flex>
        </Flex>

        <Flex justifyContent='center' alignItems='center' flexDirection='column' py='4px'>
          <Image
            src={teamB.logo}
            alt={`${teamB.name} logo`}
            boxSize='56px'
            borderRadius='50%'
            height='56px'
            width='56px'
          />
          <Text id='team2' textStyle='caption'>
            {teamB.name}
          </Text>
        </Flex>
      </Flex>

      <Divider borderColor='dark.D100' />

      <Flex justifyContent='center' alignItems='center' py='4px'>
        <Flex gap={2} alignItems='center'>
          <Badge variant='primary' textStyle='bodySmallSemibold' alignItems='center' px={2}>
            {item.startingPrice} {item.currency}
          </Badge>
        </Flex>
        <Text textStyle='bodySmallMedium'>
          <FormattedMessage id='history.price' defaultMessage={'Price'} />
        </Text>
      </Flex>
    </Box>
  );
};
