import { Modal, ModalOverlay, ModalContent, ModalCloseButton } from '@chakra-ui/react';

import { SmartphoneInstructionsContent } from './SmartphoneInstructionsContent/SmartphoneInstructionsContent';

interface ModalSmartphoneInstructionsProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ModalSmartphoneInstructions = ({
  isOpen,
  onClose,
}: ModalSmartphoneInstructionsProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />

      <ModalContent textAlign='right' minW={{ base: '100%', md: '820px' }}>
        <ModalCloseButton
          size={{ base: 'sm', md: 'lg' }}
          top={{ base: '20px', md: '35px' }}
          left={{ base: '20px', md: '30px' }}
        />
        <SmartphoneInstructionsContent />
      </ModalContent>
    </Modal>
  );
};
