import { Heading, Icon, Text, VStack } from '@chakra-ui/react';
import { ReactComponent as checkMark } from 'assets/check-mark.svg';
import { FormattedMessage } from 'react-intl';

export const SuccessHeader = () => (
  <VStack bg='green.G700' textAlign='center' justifyContent='center' gap='8px' p='40px 16px 44px'>
    <Icon as={checkMark} boxSize='68px' />
    <div>
      <Heading
        as='h1'
        variant={{ base: 'headingMedium', md: 'headingLarge' }}
        mb='4px'
        color='white'
      >
        <FormattedMessage
          id='orderSummary.paymentSuccessful'
          defaultMessage='Order confirmed, enjoy the event!'
        />
      </Heading>
      <Text textStyle='bodyLargeRegular' color='white'>
        <FormattedMessage
          id='orderSummary.paymentSuccessfulDescription'
          defaultMessage="Your tickets will be sent to each participant's phone number shortly, QR code will be available few hours before the match"
        />
      </Text>
    </div>
  </VStack>
);
