import { Badge, Box, Divider, Text, VStack, Image } from '@chakra-ui/react';
import { ConcertInfo, MatchState } from 'api/responseTypes';
import { FormattedMessage } from 'react-intl';

interface ConcertCardProps extends Omit<ConcertInfo, 'eventKey'> {
  onCardClick: () => void;
}

export const ConcertCard = ({
  state,
  onCardClick,
  date,
  time,
  imageLink,
  startingPrice,
  currency,
  title,
  address,
}: ConcertCardProps) => {
  return (
    <Box
      borderWidth='1px'
      borderRadius='4%'
      _hover={{ borderColor: state === MatchState.SOLD_OUT ? 'dark.D500' : 'green.G500' }}
      cursor={state === MatchState.SOLD_OUT ? 'not-allowed' : 'pointer'}
      pointerEvents={state === MatchState.SOLD_OUT ? 'none' : 'unset'}
      onClick={onCardClick}
      as='li'
      listStyleType={'none'}
      m='5'
      background='white'
    >
      <Box
        position='relative'
        _before={{
          content: "''",
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: '2',
        }}
      >
        <Image
          src={imageLink}
          boxSize={{ base: '100%', md: '100%', lg: '400px' }}
          height={{ base: '400px', md: '500px', lg: '400px' }}
          objectFit='fill'
          borderTopRadius={'4%'}
        />
        <Badge
          variant='solid'
          borderRadius='full'
          px={2}
          py={1}
          position='absolute'
          bottom={3}
          left={3}
          zIndex='3'
          background={'white'}
          color={'#008674'}
        >
          <FormattedMessage defaultMessage='From' id='concerts.upcoming.from' />
          {` ${startingPrice} ${currency}`}
        </Badge>
      </Box>
      <VStack spacing={4} align='start' mt={2} p={4}>
        <Text fontWeight='bold' fontSize='lg'>
          {title}
        </Text>
        <Divider />
        <Text
          textStyle='caption'
          color={state === MatchState.SOLD_OUT ? 'dark.D200' : 'dark.D500'}
          fontWeight='bold'
        >
          {date} {time}
        </Text>
        <Text
          fontWeight='bold'
          fontSize='sm'
          textStyle='bodySmallRegular'
          color={state === MatchState.SOLD_OUT ? 'dark.D500' : '#008674'}
        >
          {address}
        </Text>
      </VStack>
    </Box>
  );
};
