import { Hero } from './Hero/Hero';
import { OpenAccount } from './OpenAccount/OpenAccount';
import { TicketHowTo } from './TicketHowTo/TicketHowTo';
import { Upcoming } from './Upcoming/Upcoming';
import { Legends } from './Legends/Legends';
import { Box } from '@chakra-ui/react';
import { useSignInOnce } from 'api/auth/MiniAppAuth';
Legends;

export const HomePage = () => {
  useSignInOnce();
  const qiToken = window.qi.user?.mobile.token;

  return (
    <Box as='div'>
      <Hero />
      <Upcoming />
      {qiToken === undefined && (
        <Box>
          <TicketHowTo />
          <OpenAccount />
        </Box>
      )}
      <Legends />
    </Box>
  );
};
