import { Flex, Box, Image, Button } from '@chakra-ui/react';
import { MobileNavbar } from './MobileNavbar/MobileNavbar';
import { DesktopNavbar } from './DesktopNavbar/DesktopNavbar';
import logo from '../../assets/logo-new.svg';
import { useMediaQuery } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'router/routes';
import { FEATURE_FLAGS_ENABLED } from 'utils/constants';
import 'https://cdn.marmot-cloud.com/npm/hylid-bridge/2.10.0/index.js';
import { toast } from 'utils/toast';

export interface MyObject {
  getAuthCode: (options: {
    scopes: string[];
    success: (res: { authCode: string }) => void;
    fail: (err: any) => void;
  }) => void;
}

interface sucessProps {
  authCode: string;
}

const my: MyObject = (window as any).my;

export const Navbar = () => {
  const copyAuthCOde = () => {
    my.getAuthCode({
      scopes: ['USER_CONTACTINFO', 'auth_user'],
      success: async (res: sucessProps) => {
        const authCode = res.authCode;
        navigator.clipboard.writeText(authCode);
      },
      fail: (error) => {
        toast({
          status: 'error',
          description: `Error getting authCode! ${error}`,
        });
      },
    });
  };
  const qiToken = window.qi.user?.mobile.token;
  const [isLargerThan800] = useMediaQuery('(min-width: 767px)');

  return (
    <Box as='nav' bg='green.G900' color='green.G0' py={4} px={4} width='100vw'>
      <Flex maxW='container.xl' mx='auto' justify='end' justifyContent='space-between'>
        <Box>
          {isLargerThan800 ? <DesktopNavbar /> : <MobileNavbar />}
          {FEATURE_FLAGS_ENABLED && qiToken === undefined ? (
            <Link to={ROUTES.featureflag}>
              <Button variant='brandMedium'>Testing Tools 🧰</Button>
            </Link>
          ) : null}
          {FEATURE_FLAGS_ENABLED && qiToken === undefined ? (
            <Button my={'5'} variant='brandMedium' onClick={copyAuthCOde}>
              copy auth code{' '}
            </Button>
          ) : null}
        </Box>
        <Link to={ROUTES.home} aria-label='Go to homepage'>
          <Image
            src={logo}
            w={{ base: '125px', md: '120px' }}
            h={{ base: '32px', md: '62px' }}
            alt='ticket zone logo'
          />
        </Link>
      </Flex>
    </Box>
  );
};
