import { Box, Flex, Heading, Spinner } from '@chakra-ui/react';
import { ConcertCard } from 'components/ConcertCard/ConcertCard';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useUpcomingConcertsQuery } from './hooks';
//import announcemnt from '../../../assets/announcement.png';

export const Upcoming = () => {
  const navigate = useNavigate();

  const { isLoading, data } = useUpcomingConcertsQuery();

  const handleCardClick = (concertId: number, eventId: number) => {
    navigate('/concert/' + concertId + '/' + eventId);
  };

  return (
    <Box
      as='section'
      bg='BG100'
      py='36px'
      color='dark.D900'
      textAlign='right'
      aria-labelledby='headingUpcoming'
    >
      <Box>
        <Heading
          textAlign={'center'}
          id='headingUpcoming'
          mb='24px'
          variant={{ base: 'headingMedium', md: 'headingLarge' }}
        >
          <FormattedMessage defaultMessage='Upcoming Concerts' id='concerts.upcoming.heading' />
        </Heading>
        {isLoading ? (
          <Flex align='center' justify='center' mt='50px' mb='50px'>
            <Spinner color='green.G50' size='xl' thickness='4px' />
          </Flex>
        ) : data?.items.length ? (
          <Flex
            flexDirection={{ base: 'column', lg: 'row' }}
            justifyContent='center'
            alignItems='center'
            as='ul'
            width='100vw'
            listStyleType='none'
          >
            {data.items.map((concert) => (
              <ConcertCard
                {...concert}
                key={concert.id}
                onCardClick={() => handleCardClick(concert.id, concert.eventId)}
              />
            ))}
          </Flex>
        ) : (
          <div></div>
        )}
      </Box>
    </Box>
  );
};
