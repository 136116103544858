import alArabiaLogo from 'assets/alrabia-logo.webp';
import digitalZoneLogo from 'assets/digitalzone-logo.webp';
import partner1 from 'assets/iraq-football-association.webp';
import partner2 from 'assets/iraqi-ministry-of-youth-and-sport.webp';
import qiCardLogo from 'assets/qi-card-logo.webp';

export const sponsorItems = [
  {
    src: digitalZoneLogo,
    content: 'about.digitalZoneDescription',
    header: 'about.digitalZone',
    defaultHeaderMessage: 'Digital Zone',
    defaultContentMessage:
      'Platform designed to sell any digital products. Directly to individuals or in-direct to Retailer and Coocpratove entities',
  },
  {
    src: qiCardLogo,
    content: 'about.qiCardDescription',
    header: 'about.qiCard',
    defaultHeaderMessage: 'Qi Card',
    defaultContentMessage:
      'QiCard (Mixed Sector) was established in 2007 as a joint venture between the private sector, represented by the Iraqi Electronic Payment Systems and the Governmental Sector, represented by the largest bank in the country, Rafidain Bank. The company has 14 years of experience in issuing biometric identification cards for citizens and providing electronic financial services in Iraq.',
  },
  {
    src: alArabiaLogo,
    content: 'about.alArabiaDescription',
    header: 'about.alArabia',
    defaultHeaderMessage: 'Alrabiaa Tv',
    defaultContentMessage:
      "Al-Rabaa Media Corporation, founded in 2021, is a network of channels and news agencies led by ambitious young journalists dedicated solely to Iraq and its interests. Al-Rabaa, part of this media conglomerate, offers diverse programs objectively covering politics, economics, science, and art, providing viewers a clear reflection of reality. With content suitable for all ages, Al-Rabaa informs and engages its audience, fostering awareness among citizens. Their unwavering commitment to journalistic integrity and Iraq's welfare makes them a vital source of information within and beyond the nation.",
  },
];

export const partnerCards = [
  {
    img: partner1,
    alt: 'partner 1',
    header: 'about.iraqiFootballAssociation',
    content: 'about.iraqiFootballAssociationDescription',
    defaultHeaderMessage: 'Iraqi Football Association',
    defaultContentMessage:
      'The Iraq Football Association (IFA) (Arabic: الاتحاد العراقي لكرة القدم) is the governing body of football in Iraq, controlling the Iraqi national team and the Iraqi Premier League.The Iraqi Football Association was founded in 1948 and has been a member of FIFA since 1950, the Asian Football Confederation since 1970, and the Sub-confederation regional body West Asian Football Federation since 2001. Iraq is also part of the Union of Arab Football Associations (founded in 1974) and the Arab Gulf Cup Football Federation (founded in 2016). The Iraqi team is commonly known as Usood Al-Rafidain (Arabic: أسود الرافدين), which literally means Lions of Mesopotamia.',
  },
  {
    img: partner2,
    alt: 'partner 2',
    header: 'about.iraqiMinistryOfYouthAndSport',
    content: 'about.iraqiMinistryOfYouthAndSportDescription',
    defaultHeaderMessage: 'Iraqi Ministry of Youth and Sport',
    defaultContentMessage:
      "The Ministry of Youth and Sports in Iraq is a government organization dedicated to uplifting the aspirations and well-being of the nation's young population. Established with a vision to empower and support the youth, the ministry plays a pivotal role in providing opportunities for personal growth, skill development, and social engagement.",
  },
];
