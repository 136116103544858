import { Box, Text, Heading, useMediaQuery } from '@chakra-ui/react';

import { FormattedMessage } from 'react-intl';
import { InfoSectionDesktop, InfoSectionMobile } from './InfoSection/InfoSection';

export const TicketHowTo = () => {
  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)');
  return (
    <Box
      as='section'
      bg='white'
      pt='56px'
      px={{ base: '16px', md: '100px' }}
      pb='64px'
      color='dark.D900'
      textAlign='right'
      aria-labelledby='howToTicketHeading'
      width='100vw'
    >
      <Box maxW='container.xl' mx='auto'>
        <Heading
          mb='16px'
          textAlign={{ base: 'center', md: 'right' }}
          variant={{ base: 'headingMedium', md: 'headingLarge' }}
          id='howToTicketHeading'
        >
          <FormattedMessage
            id='homepage.tickethowto.header'
            defaultMessage='How to buy online ticket'
          />
        </Heading>
        <Text as='h3' textAlign={{ base: 'center', md: 'right' }} color='dark.D500'>
          <FormattedMessage
            id='homepage.tickethowto.subtitle'
            defaultMessage='Grab your online ticket at ease with 3 steps'
          />
        </Text>
        {isLargerThan1280 ? <InfoSectionDesktop /> : <InfoSectionMobile />}
      </Box>
    </Box>
  );
};
