import { Box, Text, Heading, HStack, Image, VStack, Divider, Flex, Show } from '@chakra-ui/react';
import legendsBG from 'assets/legendsBG.webp';
import adnanDarjalImg from 'assets/adnanDarjal.webp';
import ahmadRadhiImg from 'assets/ahmadRadhi.webp';

import { FormattedMessage } from 'react-intl';

export const Legends = () => {
  return (
    <Box
      as='section'
      pt='56px'
      px={{ base: '16px', md: '100px' }}
      pb='64px'
      color='dark.D900'
      bgImg={{ base: 'none', md: legendsBG }}
      backgroundSize='cover'
      backgroundPosition='center'
      width='100vw'
    >
      <Box maxW='container.xl' mx='auto'>
        <Heading
          mb='16px'
          textAlign={{ base: 'center', md: 'right' }}
          variant={{ base: 'headingMedium', md: 'headingLarge' }}
          id='howToTicketHeading'
        >
          <FormattedMessage id='homepage.legends.header' defaultMessage='Iraq legends' />
        </Heading>

        <Flex
          direction={{ base: 'column', md: 'row' }}
          gap={{ base: '40px', md: '24px' }}
          mt={{ base: '36px', md: '64px' }}
        >
          <HStack flex='1' gap='24px'>
            <VStack flex='1' gap='16px' alignItems='end'>
              <Text
                as='h3'
                variant='subtitleLarge'
                fontSize='50px'
                lineHeight='60px'
                color='green.G500'
                textAlign='right'
              >
                <FormattedMessage id='homepage.legends.ahmadRadhi' defaultMessage='Ahmad Radhi' />
              </Text>
              <HStack gap='24px'>
                <VStack alignItems='end'>
                  <Text variant='bodyLargeRegular' textAlign='right'>
                    <FormattedMessage
                      id='homepage.legends.nrOfGames'
                      defaultMessage='Number of games'
                    />
                  </Text>
                  <Heading variant='headlineLarge'>31</Heading>
                </VStack>
                <VStack alignItems='end'>
                  <Text variant='bodyLargeRegular' textAlign='right'>
                    <FormattedMessage
                      id='homepage.legends.nrOfGoals'
                      defaultMessage='Number of goals'
                    />
                  </Text>
                  <Heading variant='headlineLarge'>23</Heading>
                </VStack>
              </HStack>

              <Divider borderColor='dark.D200' />

              <HStack gap='24px' alignItems='start'>
                <VStack alignItems='end'>
                  <Text textAlign='right'>
                    <FormattedMessage id='homepage.legends.height' defaultMessage='Height:' /> 186
                  </Text>
                </VStack>
                <VStack alignItems='end'>
                  <Text textAlign='right'>
                    <FormattedMessage id='homepage.legends.position' defaultMessage='Position:' />{' '}
                    <FormattedMessage id='homepage.legends.offensive' defaultMessage='Offensive' />
                  </Text>
                  <Text textAlign='right'>
                    <FormattedMessage id='homepage.legends.birth' defaultMessage='Born:' /> 1964
                  </Text>
                </VStack>
              </HStack>

              <Divider borderColor='dark.D200' />

              <Text textAlign='right'>
                <FormattedMessage
                  id='homepage.legends.ahmadRadhiDescription'
                  defaultMessage='He is one of the most prominent players of the golden era of Iraqi football.'
                />
              </Text>
            </VStack>
            <Show above='md'>
              <Box flex='1'>
                <Image alt='image of ahmad radhi' src={ahmadRadhiImg} maxH='555px' rounded='16px' />
              </Box>
            </Show>
          </HStack>

          <HStack flex='1' gap='24px'>
            <Show above='md'>
              <Box flex='1'>
                <Image
                  alt='photo of adnan darjal'
                  src={adnanDarjalImg}
                  rounded='16px'
                  maxH='555px'
                />
              </Box>
            </Show>
            <VStack gap='16px' alignItems='end' flex='1'>
              <Text
                variant='subtitleLarge'
                fontSize='50px'
                lineHeight='60px'
                color='green.G500'
                textAlign='end'
              >
                <FormattedMessage id='homepage.legends.adnanDarjal' defaultMessage='Adnan Darjal' />
              </Text>

              <Divider borderColor='dark.D200' />

              <HStack gap='24px' alignItems='start'>
                <VStack alignItems='end'>
                  <Text textAlign='right'>
                    <FormattedMessage id='homepage.legends.height' defaultMessage='Height:' /> 190
                  </Text>
                </VStack>
                <VStack alignItems='end'>
                  <Text textAlign='right'>
                    <FormattedMessage id='homepage.legends.position' defaultMessage='Position:' />{' '}
                    <FormattedMessage id='homepage.legends.defender' defaultMessage='Defender' />
                  </Text>
                  <Text textAlign='right'>
                    <FormattedMessage id='homepage.legends.birth' defaultMessage='Born:' /> 1960
                  </Text>
                </VStack>
              </HStack>

              <Divider borderColor='dark.D200' />

              <Text textAlign='end'>
                <FormattedMessage
                  id='homepage.legends.adnanDarjalDescription1'
                  defaultMessage='Iraqi football player and coach. President of the Iraqi Football Union'
                />
                <FormattedMessage
                  id='homepage.legends.adnanDarjalDescription2'
                  defaultMessage="He had the nickname of Dukan’s dam as a player and General as a trainer. He also holds records for the most Olympic matches, winning three of Iraq's 10 matches and the most match in the Gulf Championships. He earned a number of titles and honors in his accomplished career."
                />
              </Text>
            </VStack>
          </HStack>
        </Flex>
      </Box>
    </Box>
  );
};
