import { VStack, HStack, Flex, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { OrderSummaryResponse } from 'api/responseTypes';
import { TicketPrice } from './TicketPrice/TicketPrice';

type OrderDetailsProps = Pick<
  OrderSummaryResponse,
  'orderNumber' | 'totalPrice' | 'tickets' | 'currency'
>;

export const OrderDetails = ({ orderNumber, totalPrice, tickets, currency }: OrderDetailsProps) => {
  return (
    <VStack spacing='16px' align='stretch' bg='white' borderRadius='16px' p='24px'>
      <Flex direction='column' gap='8px' textAlign='end'>
        <Text variant='bodyLargeSemiBold'>
          <FormattedMessage id='orderSummary.orderDetails' defaultMessage={'Order details:'} />
        </Text>
        <HStack justify='space-between' mt='2px'>
          <Text variant='bodySmallRegular'>{orderNumber}</Text>
          <Text variant='bodySmallRegular'>
            <FormattedMessage id='orderSummary.orderNumber' defaultMessage={'Order number'} />
          </Text>
        </HStack>
        <TicketPrice tickets={tickets} currency={currency} />
        <HStack justifyContent='space-between'>
          <Text variant='bodyLargeSemiBold'>
            {totalPrice} {currency}
          </Text>
          <Text variant='bodyLargeSemiBold'>
            <FormattedMessage id='orderSummary.total' defaultMessage={'Total'} />
          </Text>
        </HStack>
      </Flex>
    </VStack>
  );
};
