import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'router/routes';
import { useEffect } from 'react';
import { TicketHolder } from './TicketHolder/TicketHolder';
// import { GameInfo } from './GameInfo/GameInfo';
import { Grid, GridItem, VStack, Show } from '@chakra-ui/react';
import { useOrderSummaryQuery } from './hooks/useOrderSummaryQuery';
import { OrderDetails } from './OrderDetails/OrderDetails';
import { LoadingView } from './LoadingView/LoadingView';
import { SuccessHeader } from './SuccessHeader/SuccessHeader';
import { TicketInstruction } from './TicketInstruction/TicketInstruction';

export const OrderSummaryPage = () => {
  const navigate = useNavigate();

  const search = useLocation().search;
  const orderID = new URLSearchParams(search).get('CartID') ?? '';
  const { data, isFetching, isError } = useOrderSummaryQuery(orderID);

  useEffect(() => {
    if (!orderID) {
      navigate(ROUTES.home);
    }
  }, [orderID, navigate]);

  if (isFetching) {
    return <LoadingView />;
  }

  return !isError && data ? (
    <>
      <SuccessHeader />
      <Grid
        mx='auto'
        maxW='container.xl'
        templateColumns={{ base: '1fr', md: '1fr 1fr' }}
        gap={{ base: '16px', md: '24px' }}
        p={{ base: '16px', md: '24px 16px 64px' }}
      >
        <GridItem>
          <TicketHolder tickets={data.tickets} />
        </GridItem>
        <GridItem gridRow={{ base: 1, md: 0 }}>
          <VStack gap='8px' align='stretch'>
            {/* <GameInfo {...data.item} /> */}
            <OrderDetails {...data} />
            <Show above='md'>
              <TicketInstruction />
            </Show>
          </VStack>
        </GridItem>
        <Show below='md'>
          <GridItem>
            <TicketInstruction />
          </GridItem>
        </Show>
      </Grid>
    </>
  ) : null;
};
