import { Box, Button, Circle, Divider, Flex, Text, VStack } from '@chakra-ui/react';
import { LoaderOverlay } from 'components/LoaderOverlay/LoaderOverlay';
import { useParams } from 'react-router-dom';
import { QrCode } from './QrCode/QrCode';
import { QrCodeSuccess } from './QrCode/QrCodeSuccess/QrCodeSuccess';
import { QrCodeNotAvailable } from './QrCode/QrCodeNotAvailable/QrCodeNotAvailable';
import { TicketInfo } from './TicketInfo/TicketInfo';
import { useTicketQuery } from './useTicket';
import { Sponsors } from 'components/Sponsors/Sponsors';
import { EventAnnouncements } from './TicketInfo/EventAnnouncements';

export const TicketGeneratorPage = () => {
  const { ticketId } = useParams();
  const { data, isFetching } = useTicketQuery(ticketId);
  if (isFetching) {
    return <LoaderOverlay />;
  }

  if (!data) return null;

  return (
    <Flex justify='center' bg='#005E51' minH='100vh' maxW={'100vw'}>
      <VStack>
        <Box bg={'white'} m={'20px'} borderRadius={'20px'}>
          <VStack width={{ base: '100%', md: '460px' }} align='stretch'>
            <EventAnnouncements event_id={data.event.id}></EventAnnouncements>
            <VStack
              borderRadius='16px'
              borderColor='white'
              borderWidth='1px'
              p='16px'
              textAlign='center'
              minH='252px'
              justifyContent='center'
            >
              {!data.used && !data.qrCode ? (
                <Box>
                  <QrCodeNotAvailable statusMessage={data.qrCodeStatus} />
                  <Text textAlign={'right'} pt={'10'}>
                    اخزن لقطة الشاشة عند ظهور الرمز السري لتتمكن من الدخول الى العرض
                  </Text>
                </Box>
              ) : null}
              {!data.used && data.qrCode ? <QrCode idToEncode={data.qrCode} /> : null}
              {data.used ? <QrCodeSuccess /> : null}
            </VStack>
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              bg={'black'}
              position={'relative'}
            >
              <Circle
                size={'38.13px'}
                bg={'#005E51'}
                top={'-10'}
                position={'absolute'}
                left={'-19.065px'}
              />

              <Divider
                position={'absolute'}
                gap={'0'}
                borderColor='dark.D400'
                variant={'dashed'}
                top={'-5'}
                mx={'auto'}
                width={'80%'}
                left={'0'}
                right={'0'}
              />

              <Circle
                size={'38.13px'}
                bg={'#005E51'}
                top={'-10'}
                position={'absolute'}
                right={'-19.065px'}
              />
            </Box>
            <TicketInfo
              item={data}
              gate={data.gate}
              holderName={data.holderName}
              holderPhone={data.holderPhone}
              sector={data.sector}
              isMatch={data.event.eventType.toString() == 'SPORT'}
            />
          </VStack>
        </Box>
        <Button
          width={{ base: '90%', md: '460px' }}
          borderRadius={'14.3px'}
          bg={'#F9F9F9'}
          color={'#005E51'}
          onClick={() => window.print()}
        >
          اضغظ هنا لحفظ التذكرة
        </Button>
        <Box justifyContent='center' alignSelf={'center'} p='16px'>
          <Sponsors autoGap />
        </Box>
      </VStack>
    </Flex>
  );
};
