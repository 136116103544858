import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ROUTES } from 'router/routes';
import { ReactComponent as UserIcon } from 'assets/user.svg';
import { ReactComponent as PurchaseIcon } from 'assets/purchase.svg';

interface ProfileNavigationProps {
  currentPathname: string;
  variant?: 'mobile' | 'desktop';
}

export const ProfileNavigation = ({
  currentPathname,
  variant = 'mobile',
}: ProfileNavigationProps) => {
  return (
    <Flex
      direction={{ base: 'row-reverse', lg: 'column' }}
      shrink='0'
      marginBottom={{ base: 4, lg: 0 }}
    >
      <Box
        as={Link}
        to={ROUTES.profile}
        aria-label='Go to General information'
        display='flex'
        flexDirection={{ base: 'row-reverse', lg: 'row' }}
        alignItems='center'
        justifyContent={{ base: 'center', lg: 'flex-end' }}
        gap='8px'
        borderColor={currentPathname === '/profile' ? 'green.G500' : 'dark.D100'}
        paddingRight='8px'
        paddingY='12px'
        width={{ base: '50%', lg: '100%' }}
        borderRightWidth={currentPathname === '/profile' && variant === 'desktop' ? '2px' : '0px'}
        borderBottomWidth={{ base: '2px', lg: '0px' }}
      >
        <Text
          variant={'bodyLargeSemiBold'}
          color={currentPathname === '/profile' ? 'green.G500' : 'dark.D500'}
          textAlign='right'
        >
          <FormattedMessage
            id='profile.generalInformation'
            defaultMessage={'General Information'}
          />
        </Text>
        <Icon
          as={UserIcon}
          boxSize={5}
          fill={currentPathname === '/profile' ? 'green.G500' : 'dark.D500'}
        />
      </Box>
      <Box
        as={Link}
        to={ROUTES.history}
        aria-label='Go to order history'
        display='flex'
        flexDirection={{ base: 'row-reverse', lg: 'row' }}
        alignItems='center'
        justifyContent={{ base: 'center', lg: 'flex-end' }}
        gap='8px'
        borderColor={currentPathname === '/history' ? 'green.G500' : 'dark.D100'}
        paddingRight='8px'
        paddingY='12px'
        width={{ base: '50%', lg: '100%' }}
        borderRightWidth={currentPathname === '/history' && variant === 'desktop' ? '2px' : '0px'}
        borderBottomWidth={variant === 'mobile' ? '2px' : '0px'}
      >
        <Text
          variant={'bodyLargeSemiBold'}
          color={currentPathname === '/history' ? 'green.G500' : 'dark.D500'}
          textAlign='right'
        >
          <FormattedMessage id='profile.orderHistory' defaultMessage={'Order History'} />
        </Text>
        <Icon
          as={PurchaseIcon}
          boxSize={5}
          fill={currentPathname === '/history' ? 'green.G500' : 'dark.D500'}
        />
      </Box>
    </Flex>
  );
};
