import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';
import { useState } from 'react';
import { PhoneNumberContent } from './PhoneNumberContent/PhoneNumberContent';
import { OtpContent } from './OtpContent/OtpContent';

interface ModalLoginPhoneNumberProps {
  onClose: () => void;
  isOpen: boolean;
}
export interface Credential {
  phone?: string;
  session?: string;
}

export const ModalLogin = ({ isOpen, onClose }: ModalLoginPhoneNumberProps) => {
  const [credential, setCredential] = useState<Credential>({});

  // TO DO: Refactor
  const handleCredentialChange = (data: Credential) => {
    if (data.phone === undefined || data.session === undefined) return;
    setCredential(data);
  };

  const handleSessionRefresh = (session: string) => {
    setCredential((prevState) => ({
      ...prevState,
      session,
    }));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent textAlign='right'>
        {!credential.session && !credential.phone ? (
          <PhoneNumberContent onChangeCredential={handleCredentialChange} />
        ) : (
          <OtpContent
            onClose={onClose}
            credential={credential}
            onSessionRefresh={handleSessionRefresh}
          />
        )}
      </ModalContent>
    </Modal>
  );
};
