import { Divider, Flex, HStack, Image, Text, VStack, Heading, Box, Center } from '@chakra-ui/react';
import { ReactComponent as PlaceholderTeamLogo } from 'assets/placeholder.svg';
import { FormattedMessage } from 'react-intl';
import IraqTeamFlag from '../../../assets/image 470.svg';
import PhilippineTeamFlag from '../../../assets/image 471.svg';

interface TicketInfoProps {
  item: any;
  gate: string;
  holderPhone: string;
  holderName: string;
  sector: string;
  isMatch: boolean;
}

export const TicketInfo = ({
  item,
  holderPhone,
  holderName,
  sector,
  gate,
  isMatch,
}: TicketInfoProps) => {
  const toSeatDirections = sector.split('-');
  return (
    <VStack align='stretch' p='16px' gap='16px' aria-labelledby='ticket information'>
      <HStack
        gap='16px'
        justifyContent='space-between'
        alignItems='flex-start'
        flexDirection={'row-reverse'}
      >
        <VStack alignItems='end' spacing='16px' width='33.33%'>
          <VStack alignItems='end' spacing='5px'>
            <Text textStyle='bodySmallMedium' color='dark.D400'>
              <FormattedMessage id='ticketGenerator.gate' defaultMessage={'Gate'} />
            </Text>
            <Text textStyle='bodyLargeSemiBold' color={'#FF5C4F'} fontSize={'15.97px'}>
              {gate}
            </Text>
          </VStack>
        </VStack>
        <VStack alignItems='end'>
          <VStack alignItems='end' spacing='5px'>
            <Text textStyle='bodySmallMedium' color='dark.D400'>
              <FormattedMessage
                id={isMatch ? 'ticketGenerator.match.section' : 'ticketGenerator.match.section'}
                defaultMessage={isMatch ? 'section' : 'section'}
              />
            </Text>
            <Text
              textStyle='bodyLargeSemiBold'
              textAlign='right'
              color={'#FF5C4F'}
              fontSize={'15.97px'}
            >
              {toSeatDirections[0]}
            </Text>
          </VStack>
        </VStack>
        <VStack alignItems='end'>
          <VStack alignItems='end' spacing='5px'>
            <Text textStyle='bodySmallMedium' color='dark.D400'>
              <FormattedMessage
                id={isMatch ? 'ticketGenerator.match.row' : 'ticketGenerator.match.row'}
                defaultMessage={isMatch ? 'row' : 'row'}
              />
            </Text>
            <Text
              textStyle='bodyLargeSemiBold'
              textAlign='right'
              color={'#FF5C4F'}
              fontSize={'15.97px'}
            >
              {toSeatDirections[1]}
            </Text>
          </VStack>
        </VStack>
        <VStack alignItems='end'>
          <VStack alignItems='end' spacing='5px'>
            <Text textStyle='bodySmallMedium' color='dark.D400'>
              <FormattedMessage
                id={
                  isMatch
                    ? 'ticketGenerator.match.seatNumber'
                    : 'ticketGenerator.concert.seatNumber'
                }
                defaultMessage={isMatch ? 'Seat Number' : 'Seat Number'}
              />
            </Text>
            <Text
              textStyle='bodyLargeSemiBold'
              textAlign='right'
              color={'#FF5C4F'}
              fontSize={'15.97px'}
            >
              {toSeatDirections[2]}
            </Text>
          </VStack>
        </VStack>
      </HStack>
      <HStack
        gap='16px'
        justifyContent='space-between'
        alignItems='center'
        flexDirection={'row-reverse'}
      >
        <VStack>
          <Text textStyle='bodySmallMedium' color='dark.D400'>
            <FormattedMessage
              id={isMatch ? 'ticketGenerator.match.date' : 'ticketGenerator.concert.date'}
              defaultMessage={'Date'}
            />
          </Text>
          <Text textStyle='bodyLargeSemiBold' textAlign='right'>
            {isMatch ? item.match.date : item.concert.date}
          </Text>
        </VStack>
        <VStack>
          {isMatch ? (
            <Text textStyle='bodySmallMedium' color='dark.D400'>
              <FormattedMessage id='ticketGenerator.kickOff' defaultMessage={'Kick off'} />
            </Text>
          ) : (
            <Text textStyle='bodySmallMedium' color='dark.D400'>
              <FormattedMessage id='ticketGenerator.starts' defaultMessage={'Starts'} />
            </Text>
          )}

          <Text textStyle='bodyLargeSemiBold'>{isMatch ? item.match.time : item.concert.time}</Text>
        </VStack>
        <VStack>
          <Text textStyle='bodySmallMedium' color='dark.D400'>
            <FormattedMessage id='ticketGenerator.gateOpens' defaultMessage={'Gate opens'} />
          </Text>
          <Text textStyle='bodyLargeSemiBold' textAlign='right'>
            <FormattedMessage
              id={
                isMatch
                  ? 'ticketGenerator.match.gateOpensDescription'
                  : 'ticketGenerator.concert.gateOpensDescription'
              }
              defaultMessage={
                isMatch ? 'gate opens 3h before the match' : 'gate opens 30min before the concert'
              }
            />
          </Text>
        </VStack>
      </HStack>
      <Divider borderColor='dark.D400' width={'50%'} alignSelf={'end'} variant={'dashed'} />
      <Flex justifyContent='space-between'>
        <VStack alignItems='end' spacing='5px'>
          <Text textStyle='bodySmallMedium' color='green.G500'>
            <FormattedMessage id='ticketGenerator.phoneNumber' defaultMessage={'Phone number'} />
          </Text>
          <Text textStyle='bodyLargeSemiBold' color='green.G900'>
            {holderPhone}
          </Text>
        </VStack>
        <VStack alignItems='end' spacing='5px' pl={'15px'}>
          <Text textStyle='bodySmallMedium' color='green.G500'>
            <FormattedMessage id='ticketGenerator.ticketHolder' defaultMessage={'Ticket Holder'} />
          </Text>
          <Text textStyle='bodyLargeSemiBold' color='green.G900'>
            {holderName}
          </Text>
        </VStack>
      </Flex>

      <Divider borderColor='dark.D400' width={'50%'} alignSelf={'end'} variant={'dashed'} />
      {isMatch && item.match ? (
        <Box>
          <Heading variant='headingMedium' textAlign='right' mb={4}>
            <Text
              textColor={'#008674'}
              fontSize={'15px'}
              fontWeight={'extrabold'}
              lineHeight={'20.97px'}
            >
              تصفيـات كــــأس العالم 2026
            </Text>
          </Heading>
          <HStack justify={'space-between'} alignItems='center' flexDirection={'row-reverse'}>
            <HStack flexDirection={'row'} justify={'center'} alignItems={'center'}>
              <Text
                textStyle='bodyLargeSemiBold'
                fontSize={{ base: '11.2px', md: '11.5px' }}
                fontWeight={'700'}
                lineHeight={'20.97px'}
                color={'#282E2E'}
                pl={'2'}
              >
                المنتخب العراقي
              </Text>
              <Image
                src={IraqTeamFlag}
                alt={'team A flag'}
                boxSize='30px'
                borderRadius='50%'
                fallback={<PlaceholderTeamLogo />}
              />
            </HStack>
            <Center height='17.16px'>
              <Divider orientation='vertical' borderColor='dark.D400' />
            </Center>
            <HStack>
              <Text
                textStyle='bodyLargeSemiBold'
                fontSize={{ base: '11.2px', md: '11.5px' }}
                fontWeight={'700'}
                lineHeight={'20.97px'}
                color={'#282E2E'}
              >
                المنتخب الفلبيني
              </Text>
              <Image
                src={PhilippineTeamFlag}
                alt={'team B flag'}
                boxSize='30px'
                borderRadius='50%'
                fallback={<PlaceholderTeamLogo />}
              />
            </HStack>
          </HStack>
        </Box>
      ) : (
        <VStack spacing='16px' p='16px' alignItems='end'>
          <HStack gap='8px'>
            <VStack>
              <Text textStyle='bodyLargeSemiBold'>{item.concert.title}</Text>
              <Divider />
              <Text textStyle='bodyMediumSemiBold'>{item.concert.address}</Text>
            </VStack>
            <Image
              src={item.concert.imageLink}
              boxSize='100px'
              borderRadius='10%'
              height='auto'
              width='100px'
            />
          </HStack>
        </VStack>
      )}
    </VStack>
  );
};
