import axios from 'axios';

export const sendRequest = async (userMobile: any) => {
  const apiUrl = 'https://in.logs.betterstack.com';

  const authToken = 'Bearer BvxgGJrqPqCKMW8mxMTqwWXt';

  const requestData = {
    message: userMobile,
  };

  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
  };

  try {
    const response = await axios.post(apiUrl, requestData, axiosConfig);
    console.log(response.data);
  } catch (error) {
    console.error('Error:', error);
  }
};
