import { Flex, Box, Image, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

type TPartnerCardProps = {
  img: string;
  alt: string;
  header: string;
  content: string;
  defaultHeaderMessage: string;
  defaultContentMessage: string;
};

export const PartnerCard = ({
  img,
  alt,
  header,
  content,
  defaultHeaderMessage,
  defaultContentMessage,
}: TPartnerCardProps) => {
  return (
    <Box maxW='286px' minH='336px' bg='white' px='32px' py='16px' mx={8}>
      <Flex flexDirection='column' w='100%'>
        <Flex justifyContent='center'>
          <Image w='auto' alt={alt} src={img} />
        </Flex>
        <Flex justifyContent='center' alignItems='center' flexDirection='column'>
          <Text align='center' variant='bodyLargestRegular' mt={4}>
            <FormattedMessage id={header} defaultMessage={defaultHeaderMessage} />
          </Text>
          <Text variant='bodySmallRegular' mt={4} color='dark.D500'>
            <FormattedMessage id={content} defaultMessage={defaultContentMessage} />
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};
